import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { CompetitionScaffoldQuery as CompetitionScaffoldQueryType } from "./__generated__/CompetitionScaffoldQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { CompetitionScaffoldQuery as CompetitionScaffoldQueryType } from "./__generated__/CompetitionScaffoldQuery.graphql";

export const CompetitionScaffoldQuery = graphql`
  query CompetitionScaffoldQuery($slug: String!) {
    viewer @ifAllowed {
      id
    }
    competitionBySlug(slug: $slug) {
      id
      slug
      title
      shortDescription
      description
      createdAt
      banner
      thumbnail
      viewerCanEdit: viewerCan(action: UPDATE_COMPETITION)
      viewerCanEditMembers: viewerCan(action: ADD_COMPETITION_MEMBER)
      viewerCanApprove: viewerCan(action: CREATE_PROJECT_VERSION_APPROVAL)
      isPrivate
      requiresApproval
      hasLeaderboard
      host {
        id
        displayName
        username
        ...EntityProfilePicFragment @arguments(thumbnail: true)
      }
      ...CompetitionSetupButtonFragment
      ...CompetitionJoinButtonFragment
    }
  }
`;

export const competitionScaffoldQueryLoader = (environment: Environment) => {
  return ({ params: { slug } }: LoaderArgs) => {
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    return preload<CompetitionScaffoldQueryType>(
      environment,
      CompetitionScaffoldQuery,
      { slug },
    );
  };
};

export const useCompetitionScaffoldQuery = () =>
  usePreloaded<CompetitionScaffoldQueryType>();
