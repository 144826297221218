import { graphql, useMutation, ConnectionHandler } from "react-relay";
import { UploadableMap } from "relay-runtime";
import { useIntl, FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import {
  ForumCreatePageMutation as ForumCreatePageMutationType,
  ForumCreatePageMutation$variables,
} from "./__generated__/ForumCreatePageMutation.graphql";
import ForumEditForm from "../components/ForumEditForm";

const ForumCreatePageMutation = graphql`
  mutation ForumCreatePageMutation(
    $input: CreateForumInput!
    $connections: [ID!]!
  ) {
    createForum(input: $input) @prependEdge(connections: $connections) {
      node {
        id
        title
        shortDescription
        guidelines
        slug
        icon
        orderingPriority
      }
    }
  }
`;

export default function ForumCreatePage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const [commitMutation, isMutationInFlight] =
    useMutation<ForumCreatePageMutationType>(ForumCreatePageMutation);

  return (
    <div>
      <h1 className="text-xl font-bold pb-4 font-poppins">
        <FormattedMessage defaultMessage={"Create Forum"} />
      </h1>
      <ForumEditForm
        routePrefix="discussions"
        autoSlug={true}
        onSubmit={({ data, icon, setFormError }) => {
          const variables: ForumCreatePageMutation$variables = {
            input: {
              title: data.title,
              shortDescription: data.shortDescription,
              guidelines: data.guidelines,
              slug: data.slug,
              icon: icon.variable,
              orderingPriority: data.orderingPriority,
            },
            connections: [
              ConnectionHandler.getConnectionID(
                "root",
                "ForumsFragment_forums",
              ),
            ],
          };
          const uploadables: UploadableMap = {};
          if (icon.uploadable) {
            uploadables["variables.input.icon"] = icon.uploadable;
          }
          commitMutation({
            variables,
            uploadables,
            onError: (error) => {
              console.error(error);
              setFormError(
                intl.formatMessage({
                  defaultMessage:
                    "Could not create forum. The forum URL may already be taken.",
                }),
              );
            },
            onCompleted: ({ createForum: { node } }) => {
              navigate(`/discussions/${node.slug}`);
            },
          });
        }}
        isDisabled={isMutationInFlight}
        submitLabel={<FormattedMessage defaultMessage="Create" />}
      />
    </div>
  );
}
