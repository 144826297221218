import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { EventMembersPageQuery as EventMembersPageQueryType } from "./__generated__/EventMembersPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { EventMembersPageQuery as EventMembersPageQueryType } from "./__generated__/EventMembersPageQuery.graphql";

export const EventMembersPageQuery = graphql`
  query EventMembersPageQuery($slug: String!) {
    ...UserAutocompleteFragment
    eventBySlug(slug: $slug) {
      id
      invitationsCount
      ...EventMembersFragment
      ...EventMembersEditFormFragment
      ...EventMembersImportFragment
      ...EventInvitationsFragment
    }
  }
`;

export const eventMembersPageQueryLoader = (environment: Environment) => {
  return ({ params: { slug } }: LoaderArgs) => {
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    return preload<EventMembersPageQueryType>(
      environment,
      EventMembersPageQuery,
      { slug },
    );
  };
};

export const useEventMembersPageQuery = () =>
  usePreloaded<EventMembersPageQueryType>();
