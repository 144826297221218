import * as Drawer from "../Drawer";
import * as Modal from "./Modal";
import { forwardRef, type PropsWithChildren } from "react";
import { DESKTOP_MEDIA_QUERY } from "../../common/constants";
import { useMediaQuery } from "../../utils/hooks";
import { cn } from "../../utils/tailwind";

export interface RootCredenzaProps extends PropsWithChildren {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export interface CredenzaProps {
  className?: string;
  asChild?: true;
  onClick?: () => void;
}

const Root = ({ children, ...props }: RootCredenzaProps) => {
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
  const Credenza = isDesktop ? Modal.Root : Drawer.Root;

  return <Credenza {...props}>{children}</Credenza>;
};

const Portal = ({ children }: PropsWithChildren) => {
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
  const Portal = isDesktop ? Modal.Portal : Drawer.Portal;
  return <Portal>{children}</Portal>;
};

const Trigger = ({
  className,
  children,
  ...props
}: PropsWithChildren<CredenzaProps>) => {
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
  const CredenzaTrigger = isDesktop ? Modal.Trigger : Drawer.Trigger;

  return (
    <CredenzaTrigger className={className} {...props}>
      {children}
    </CredenzaTrigger>
  );
};

const Overlay = forwardRef<HTMLDivElement, CredenzaProps>((props, ref) => {
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
  const CredenzaOverlay = isDesktop ? Modal.Overlay : Drawer.Overlay;
  return <CredenzaOverlay ref={ref} {...props} />;
});

const Close = ({
  className,
  children,
  ...props
}: PropsWithChildren<CredenzaProps>) => {
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
  const CredenzaClose = isDesktop ? Modal.Close : Drawer.Close;

  return (
    <>
      <CredenzaClose className={className} {...props}>
        {children}
      </CredenzaClose>
    </>
  );
};

const CloseDesktop = ({
  className,
  children,
  ...props
}: PropsWithChildren<CredenzaProps>) => {
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);

  return (
    <>
      {isDesktop && (
        <Modal.Close className={className} {...props}>
          {children}
        </Modal.Close>
      )}
    </>
  );
};

const CloseMobile = ({
  className,
  children,
  ...props
}: PropsWithChildren<CredenzaProps>) => {
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);

  return (
    <>
      {!isDesktop && (
        <Drawer.Close className={className} {...props}>
          {children}
        </Drawer.Close>
      )}
    </>
  );
};

const Content = forwardRef<HTMLDivElement, PropsWithChildren<CredenzaProps>>(
  (props, ref) => {
    const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
    const CredenzaContent = isDesktop ? Modal.Content : Drawer.Content;
    return <CredenzaContent ref={ref} {...props} />;
  },
);

const Description = ({
  className,
  children,
  ...props
}: PropsWithChildren<CredenzaProps>) => {
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
  const CredenzaDescription = isDesktop
    ? Modal.Description
    : Drawer.Description;

  return (
    <CredenzaDescription className={className} {...props}>
      {children}
    </CredenzaDescription>
  );
};

const Header = ({
  className,
  children,
  ...props
}: PropsWithChildren<CredenzaProps>) => {
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
  const CredenzaHeader = isDesktop ? Modal.Header : Drawer.Header;

  return (
    <CredenzaHeader className={className} {...props}>
      {children}
    </CredenzaHeader>
  );
};

const Title = ({
  className,
  children,
  ...props
}: PropsWithChildren<CredenzaProps>) => {
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
  const CredenzaTitle = isDesktop ? Modal.Title : Drawer.Title;

  return (
    <CredenzaTitle className={className} {...props}>
      {children}
    </CredenzaTitle>
  );
};

const Body = ({
  className,
  children,
  ...props
}: PropsWithChildren<CredenzaProps>) => {
  return (
    <div className={cn("px-4 md:px-0", className)} {...props}>
      {children}
    </div>
  );
};

const Footer = ({
  className,
  children,
  ...props
}: PropsWithChildren<CredenzaProps>) => {
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
  const CredenzaFooter = isDesktop ? Modal.Footer : Drawer.Footer;

  return (
    <CredenzaFooter className={className} {...props}>
      {children}
    </CredenzaFooter>
  );
};

Close.Desktop = CloseDesktop;
Close.Mobile = CloseMobile;

export {
  Root,
  Portal,
  Trigger,
  Overlay,
  Close,
  Content,
  Description,
  Header,
  Title,
  Body,
  Footer,
};
