/**
 * @generated SignedSource<<bd7c6b1cd4c8c7c58b181d23300cd28c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EventInvitePageAcceptMutation$variables = {
  code: string;
  id: string;
};
export type EventInvitePageAcceptMutation$data = {
  readonly acceptEventInvite: {
    readonly event: {
      readonly id: string;
      readonly slug: string;
    };
    readonly id: string;
  };
};
export type EventInvitePageAcceptMutation = {
  response: EventInvitePageAcceptMutation$data;
  variables: EventInvitePageAcceptMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "code"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "code",
        "variableName": "code"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "EventInvite",
    "kind": "LinkedField",
    "name": "acceptEventInvite",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "event",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EventInvitePageAcceptMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EventInvitePageAcceptMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "2aae23c424dc52808a971db528008764",
    "id": null,
    "metadata": {},
    "name": "EventInvitePageAcceptMutation",
    "operationKind": "mutation",
    "text": "mutation EventInvitePageAcceptMutation(\n  $id: ID!\n  $code: String!\n) {\n  acceptEventInvite(id: $id, code: $code) {\n    id\n    event {\n      id\n      slug\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "32b356d99ad0740d8c27ce020c99df4c";

export default node;
