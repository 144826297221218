/**
 * @generated SignedSource<<d60b0cdcb8dc413a25a22876ebb17aa2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventMemberInvite = {
  email: string;
  team?: string | null | undefined;
  username?: string | null | undefined;
};
export type EventMembersImportMutation$variables = {
  eventId: string;
  invites: ReadonlyArray<EventMemberInvite>;
};
export type EventMembersImportMutation$data = {
  readonly inviteEventMembers: {
    readonly id: string;
    readonly invitationsCount: number;
    readonly " $fragmentSpreads": FragmentRefs<"EventInvitationsFragment" | "EventMembersFragment">;
  };
};
export type EventMembersImportMutation = {
  response: EventMembersImportMutation$data;
  variables: EventMembersImportMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "eventId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "invites"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "eventId",
    "variableName": "eventId"
  },
  {
    "kind": "Variable",
    "name": "invites",
    "variableName": "invites"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invitationsCount",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageThumbnail",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = [
  (v6/*: any*/),
  (v7/*: any*/),
  (v2/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      (v8/*: any*/)
    ],
    "type": "Entity",
    "abstractKey": "__isEntity"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventMembersImportMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "inviteEventMembers",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EventMembersFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EventInvitationsFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventMembersImportMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "inviteEventMembers",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": "viewerCanEdit",
            "args": [
              {
                "kind": "Literal",
                "name": "action",
                "value": "UPDATE_EVENT"
              }
            ],
            "kind": "ScalarField",
            "name": "viewerCan",
            "storageKey": "viewerCan(action:\"UPDATE_EVENT\")"
          },
          {
            "alias": "viewerCanEditMembers",
            "args": [
              {
                "kind": "Literal",
                "name": "action",
                "value": "ADD_EVENT_MEMBER"
              }
            ],
            "kind": "ScalarField",
            "name": "viewerCan",
            "storageKey": "viewerCan(action:\"ADD_EVENT_MEMBER\")"
          },
          {
            "alias": "viewerCanTransferOwnership",
            "args": [
              {
                "kind": "Literal",
                "name": "action",
                "value": "TRANSFER_EVENT_OWNERSHIP"
              }
            ],
            "kind": "ScalarField",
            "name": "viewerCan",
            "storageKey": "viewerCan(action:\"TRANSFER_EVENT_OWNERSHIP\")"
          },
          {
            "alias": "canReadAgreement",
            "args": [
              {
                "kind": "Literal",
                "name": "action",
                "value": "READ_COMPETITION_RULE_AGREEMENT"
              }
            ],
            "kind": "ScalarField",
            "name": "viewerCan",
            "storageKey": "viewerCan(action:\"READ_COMPETITION_RULE_AGREEMENT\")"
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "EventMembershipConnection",
            "kind": "LinkedField",
            "name": "members",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EventMembershipEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EventMembership",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "kind",
                        "storageKey": null
                      },
                      {
                        "alias": "viewerCanDelete",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "action",
                            "value": "REMOVE_EVENT_MEMBER"
                          }
                        ],
                        "kind": "ScalarField",
                        "name": "viewerCan",
                        "storageKey": "viewerCan(action:\"REMOVE_EVENT_MEMBER\")"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "entity",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v2/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isEntity"
                          },
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "latest",
                            "value": true
                          }
                        ],
                        "concreteType": "EventRuleAgreementConnection",
                        "kind": "LinkedField",
                        "name": "ruleAgreements",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EventRuleAgreement",
                            "kind": "LinkedField",
                            "name": "nodes",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "ruleAgreements(latest:true)"
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": "members(first:10)"
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "EventMembersFragment_members",
            "kind": "LinkedHandle",
            "name": "members"
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "EventInvitationConnection",
            "kind": "LinkedField",
            "name": "invitations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EventInvitationEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EventInvitation",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Organization",
                        "kind": "LinkedField",
                        "name": "organization",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "registeredAs",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": "invitations(first:10)"
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "EventInvitationsFragment_invitations",
            "kind": "LinkedHandle",
            "name": "invitations"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "883f8c27198088888b291c4448896ca3",
    "id": null,
    "metadata": {},
    "name": "EventMembersImportMutation",
    "operationKind": "mutation",
    "text": "mutation EventMembersImportMutation(\n  $eventId: ID!\n  $invites: [EventMemberInvite!]!\n) {\n  inviteEventMembers(eventId: $eventId, invites: $invites) {\n    id\n    invitationsCount\n    ...EventMembersFragment\n    ...EventInvitationsFragment\n  }\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  displayName\n  imageThumbnail\n}\n\nfragment EventInvitationsFragment on Event {\n  invitations(first: 10) {\n    edges {\n      node {\n        id\n        username\n        email\n        organization {\n          username\n          displayName\n          ...EntityProfilePicFragment_49kWkb\n          id\n        }\n        registeredAs {\n          username\n          displayName\n          ...EntityProfilePicFragment_49kWkb\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment EventMembersEventMembershipFragment on EventMembership {\n  id\n  kind\n  viewerCanDelete: viewerCan(action: REMOVE_EVENT_MEMBER)\n  entity {\n    __typename\n    id\n    username\n    displayName\n    ...EntityProfilePicFragment_49kWkb\n  }\n  ruleAgreements(latest: true) @ifAllowed {\n    nodes {\n      id\n    }\n  }\n}\n\nfragment EventMembersFragment on Event {\n  id\n  viewerCanEdit: viewerCan(action: UPDATE_EVENT)\n  viewerCanEditMembers: viewerCan(action: ADD_EVENT_MEMBER)\n  viewerCanTransferOwnership: viewerCan(action: TRANSFER_EVENT_OWNERSHIP)\n  canReadAgreement: viewerCan(action: READ_COMPETITION_RULE_AGREEMENT)\n  members(first: 10) {\n    edges {\n      node {\n        id\n        ...EventMembersEventMembershipFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "68e7f6391f69ff6a678ae3278357c5d1";

export default node;
