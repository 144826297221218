import { graphql, useMutation } from "react-relay";
import { UploadableMap } from "relay-runtime";
import { useForumEditPageQuery } from "./loaders/ForumEditPage";
import {
  ForumEditPageMutation as ForumEditPageMutationType,
  ForumEditPageMutation$variables,
} from "./__generated__/ForumEditPageMutation.graphql";
import { useNavigate } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import { useIntl, FormattedMessage } from "react-intl";
import ForumEditForm from "../components/ForumEditForm";
import ForumDeleteButton from "../components/ForumDeleteButton";

const ForumEditPageMutation = graphql`
  mutation ForumEditPageMutation($id: ID!, $input: UpdateForumInput!) {
    updateForum(id: $id, input: $input) {
      node {
        id
        title
        shortDescription
        guidelines
        slug
        icon
        orderingPriority
      }
    }
  }
`;

export default function ForumEditPage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { query } = useForumEditPageQuery();
  const forum = query.forumBySlug;

  const [commitMutation, isMutationInFlight] =
    useMutation<ForumEditPageMutationType>(ForumEditPageMutation);
  if (!forum) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Forum not found",
        })}
      />
    );
  }

  return (
    <div>
      <h1 className="text-xl font-bold pb-4 font-poppins">
        <FormattedMessage defaultMessage={"Edit Forum"} />
      </h1>
      <ForumEditForm
        routePrefix="discussions"
        defaultValues={forum}
        onSubmit={({ data, dirtyFields, icon, setFormError }) => {
          const variables: ForumEditPageMutation$variables = {
            id: forum.id,
            input: {
              title: dirtyFields.title ? data.title : undefined,
              shortDescription: dirtyFields.shortDescription
                ? data.shortDescription
                : undefined,
              guidelines: dirtyFields.guidelines ? data.guidelines : undefined,
              slug: dirtyFields.slug ? data.slug : undefined,
              icon: icon.variable,
              orderingPriority: dirtyFields.orderingPriority
                ? data.orderingPriority
                : undefined,
            },
          };
          const uploadables: UploadableMap = {};
          if (icon.uploadable) {
            uploadables["variables.input.icon"] = icon.uploadable;
          }
          commitMutation({
            variables,
            uploadables,
            onError: (error) => {
              console.error(error);
              setFormError(
                intl.formatMessage({
                  defaultMessage:
                    "Could not update forum. The forum URL may already be taken.",
                }),
              );
            },
            onCompleted: ({ updateForum: { node } }) => {
              navigate(`/discussions/${node.slug}`);
            },
          });
        }}
        isDisabled={isMutationInFlight}
        submitLabel={<FormattedMessage defaultMessage="Save" />}
      />
      <ForumDeleteButton id={forum.id} title={forum.title} />
    </div>
  );
}
