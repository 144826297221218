import { FormattedMessage, useIntl } from "react-intl";
import UserAutocomplete from "./UserAutocomplete";
import { useForm } from "react-hook-form";
import { useState } from "react";
import {
  ConnectionHandler,
  graphql,
  useFragment,
  useMutation,
} from "react-relay";
import { EventMembersEditFormAddMutation } from "./__generated__/EventMembersEditFormAddMutation.graphql";
import { EventMembersEditFormFragment$key } from "./__generated__/EventMembersEditFormFragment.graphql";
import { logger } from "../common/logger";
import { UserAutocompleteFragment$key } from "./__generated__/UserAutocompleteFragment.graphql";
import Button from "./Button";

const Fragment = graphql`
  fragment EventMembersEditFormFragment on Event {
    id
    viewerCanEditMembers: viewerCan(action: ADD_EVENT_MEMBER)
  }
`;

const AddMutation = graphql`
  mutation EventMembersEditFormAddMutation(
    $eventId: ID!
    $entityId: ID!
    $connections: [ID!]!
  ) {
    addEventMember(eventId: $eventId, entityId: $entityId)
      @prependEdge(connections: $connections) {
      node {
        id
        ...EventMembersEventMembershipFragment
      }
    }
  }
`;

type FormData = {
  entityId: string;
};

export interface EventMembersEditFormProps {
  event: EventMembersEditFormFragment$key;
  autocomplete: UserAutocompleteFragment$key;
}

export default function EventMembersEditForm(props: EventMembersEditFormProps) {
  const intl = useIntl();
  const event = useFragment(Fragment, props.event);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const [formError, setFormError] = useState<string | null>(null);
  const [commitAddMutation, isAddMutationInFlight] =
    useMutation<EventMembersEditFormAddMutation>(AddMutation);

  const errorMessages = {
    entityId: {
      required: intl.formatMessage({
        defaultMessage: "Please select the username from the list",
      }),
    },
  };

  const onAddSubmit = handleSubmit(({ entityId }) => {
    setFormError(null);
    commitAddMutation({
      variables: {
        eventId: event.id,
        entityId,
        connections: [
          ConnectionHandler.getConnectionID(
            event.id,
            "EventMembersFragment_members",
          ),
        ],
      },
      onError: (error) => {
        logger.error(error);
        setFormError(
          intl.formatMessage({
            defaultMessage: "Something went wrong",
          }),
        );
      },
    });
  });

  if (!event.viewerCanEditMembers) {
    return null;
  }

  return (
    <div className="flex flex-col items-end">
      <form>
        <div className="flex flex-row space-x-2">
          <div>
            <UserAutocomplete
              query={props.autocomplete}
              placeholder={intl.formatMessage({
                defaultMessage: "username",
              })}
              {...register("entityId", { required: true })}
            />
          </div>

          <div>
            <Button
              kind="primary"
              onClick={onAddSubmit}
              disabled={isAddMutationInFlight}
            >
              <span className="sm:hidden">
                <FormattedMessage defaultMessage="Add" />
              </span>
              <span className="hidden sm:inline">
                <FormattedMessage defaultMessage="Add Member" />
              </span>
            </Button>
          </div>
        </div>
      </form>

      <div>
        {typeof errors.entityId?.type === "string" && (
          <p className="text-red-500 text-sm">
            {
              errorMessages.entityId[
                errors.entityId.type as keyof typeof errorMessages.entityId
              ]
            }
          </p>
        )}
        {formError && <p className="text-red-500 text-sm">{formError}</p>}
      </div>
    </div>
  );
}
