/**
 * @generated SignedSource<<a5dc0821a0d3e2fab397a64cb944fd0d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventMembershipKind = "HOST" | "PARTICIPANT";
import { FragmentRefs } from "relay-runtime";
export type EventMembersEventMembershipFragment$data = {
  readonly entity: {
    readonly displayName: string;
    readonly id: string;
    readonly username: string;
    readonly " $fragmentSpreads": FragmentRefs<"EntityProfilePicFragment">;
  };
  readonly id: string;
  readonly kind: EventMembershipKind;
  readonly ruleAgreements: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
    }>;
  };
  readonly viewerCanDelete: boolean;
  readonly " $fragmentType": "EventMembersEventMembershipFragment";
};
export type EventMembersEventMembershipFragment$key = {
  readonly " $data"?: EventMembersEventMembershipFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventMembersEventMembershipFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventMembersEventMembershipFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": "viewerCanDelete",
      "args": [
        {
          "kind": "Literal",
          "name": "action",
          "value": "REMOVE_EVENT_MEMBER"
        }
      ],
      "kind": "ScalarField",
      "name": "viewerCan",
      "storageKey": "viewerCan(action:\"REMOVE_EVENT_MEMBER\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "entity",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "args": [
            {
              "kind": "Literal",
              "name": "thumbnail",
              "value": true
            }
          ],
          "kind": "FragmentSpread",
          "name": "EntityProfilePicFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "latest",
          "value": true
        }
      ],
      "concreteType": "EventRuleAgreementConnection",
      "kind": "LinkedField",
      "name": "ruleAgreements",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EventRuleAgreement",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": "ruleAgreements(latest:true)"
    }
  ],
  "type": "EventMembership",
  "abstractKey": null
};
})();

(node as any).hash = "b1cfcb481fefdea480fe704ed253d8f4";

export default node;
