import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { EventOverviewPageQuery as EventOverviewPageQueryType } from "./__generated__/EventOverviewPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { EventOverviewPageQuery as EventOverviewPageQueryType } from "./__generated__/EventOverviewPageQuery.graphql";

export const EventOverviewPageQuery = graphql`
  query EventOverviewPageQuery($slug: String!) {
    eventBySlug(slug: $slug) {
      id
      description
      viewerCanUpdate: viewerCan(action: UPDATE_EVENT)
      ...EventCompetitionsCarouselFragment
      ...EventTeamsFragment
      ...MemberSelectEventFragment
    }
  }
`;

export const eventOverviewPageQueryLoader = (environment: Environment) => {
  return ({ params: { slug } }: LoaderArgs) => {
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    return preload<EventOverviewPageQueryType>(
      environment,
      EventOverviewPageQuery,
      {
        slug,
      },
    );
  };
};

export const useEventOverviewPageQuery = () =>
  usePreloaded<EventOverviewPageQueryType>();
