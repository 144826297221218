import { parse as csvparse } from "csv-parse/browser/esm/sync";
import { useCallback, useEffect, useMemo, useState } from "react";
import { graphql, useFragment, useMutation } from "react-relay";
import { IoMdTrash } from "react-icons/io";
import { MdArrowLeft, MdArrowRight } from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "sonner";
import { logger } from "../common/logger";
import Button from "./Button";
import * as Dialog from "./Dialog";
import { cn } from "../utils/tailwind";
import { truthOrFail } from "../utils/helpers";
import { EventMembersImportMutation } from "./__generated__/EventMembersImportMutation.graphql";
import { EventMembersImportFragment$key } from "./__generated__/EventMembersImportFragment.graphql";

const Fragment = graphql`
  fragment EventMembersImportFragment on Event {
    id
    invite {
      code @ifAllowed
    }
    viewerCanEditMembers: viewerCan(action: ADD_EVENT_MEMBER)
  }
`;

const Mutation = graphql`
  mutation EventMembersImportMutation(
    $eventId: ID!
    $invites: [EventMemberInvite!]!
  ) {
    inviteEventMembers(eventId: $eventId, invites: $invites) {
      id
      invitationsCount
      ...EventMembersFragment
      ...EventInvitationsFragment
    }
  }
`;

type CsvData = string[][];

type State =
  | { type: "initial" }
  | { type: "closed" }
  | { type: "loading" }
  | { type: "loaded"; data: CsvData };

export interface EventMembersImportProps {
  event: EventMembersImportFragment$key;
  disabled?: boolean | undefined;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export default function EventMembersImport(props: EventMembersImportProps) {
  const event = useFragment(Fragment, props.event);
  const [state, setState] = useState<State>({ type: "initial" });
  const canInvite = !!event.invite.code;

  const { onOpenChange } = props;
  const onDialogOpenChange = useCallback(
    (isOpen: boolean) => {
      onOpenChange(isOpen);
      if (!isOpen) {
        setState({ type: "initial" });
      }
    },
    [onOpenChange, setState],
  );

  const onStartImporting = useCallback(async () => {
    if (!canInvite) {
      toast.error(
        <FormattedMessage defaultMessage="Please create an invitation code first" />,
      );
      return;
    }
    setState({ type: "loading" });
    try {
      const importFile = await promptFile();
      if (!importFile) {
        toast.info(
          <FormattedMessage defaultMessage="No files were selected" />,
        );
        onDialogOpenChange(false);
        return;
      }

      const importData = csvparse(await readFileAsText(importFile));
      setState({ type: "loaded", data: importData });
    } catch (error) {
      logger.error(error, "cannot import members");
      toast.error(
        <FormattedMessage defaultMessage="There was an error loading the file you selected, please select a valid CSV file" />,
      );
      setState({ type: "initial" });
    }
  }, [setState, onDialogOpenChange, canInvite]);

  useEffect(() => {
    if (props.open && state.type === "initial") {
      onStartImporting();
    }
  }, [props.open, state, onStartImporting]);

  if (!event.viewerCanEditMembers) {
    return null;
  }

  return (
    <Dialog.Root
      open={props.open && state.type === "loaded"}
      onOpenChange={onDialogOpenChange}
    >
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content>
          <div className="flex flex-row items-center px-4 md:py-2 py-4">
            <Dialog.Title className="flex-1">
              <FormattedMessage defaultMessage="Review member import" />
            </Dialog.Title>

            <Dialog.Close />
          </div>

          {state.type === "loaded" && (
            <EventMembersImportDialog
              event={props.event}
              data={state.data}
              onClose={() => onDialogOpenChange(false)}
            />
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

interface EventMembersImportDialogProps {
  event: EventMembersImportFragment$key;
  data: CsvData;
  onClose: () => void;
}

function EventMembersImportDialog(props: EventMembersImportDialogProps) {
  const event = useFragment(Fragment, props.event);
  const pageSize = 8;
  const [state, setState] = useState<CsvDataTableState>({
    data: props.data,
    headers: ["username", "email", "team"],
    page: 0,
  });
  const [importError, setImportError] = useState(false);
  const [commitMutation, isMutationInFlight] =
    useMutation<EventMembersImportMutation>(Mutation);

  const onConfirm: React.MouseEventHandler<HTMLButtonElement> = async (
    _evt,
  ) => {
    commitMutation({
      variables: {
        eventId: event.id,
        invites: Array.from(transformCsvData(state.data, state.headers)),
      },
      onCompleted: (_, errors) => {
        if (errors?.length) {
          logger.error(errors, "cannot invite event members");
          setImportError(true);
        } else {
          props.onClose();
          toast.info(
            <FormattedMessage defaultMessage="CSV file successfully imported!" />,
          );
        }
      },
      onError: (error) => {
        logger.error(error, "cannot invite event members");
        setImportError(true);
      },
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <CsvDataTable
        state={state}
        onChangeState={setState}
        pageSize={pageSize}
        className="block overflow-x-auto"
      />

      <div className="flex justify-between items-center gap-1 px-4 py-2 sm:p-0">
        <div>
          <Pagination
            current={state.page}
            onCurrentChange={(page) =>
              setState((state) => ({ ...state, page }))
            }
            size={pageSize}
            total={state.data.length}
          />
        </div>

        <div>
          <Dialog.Close asChild>
            <Button kind="text">
              <FormattedMessage defaultMessage="Cancel" />
            </Button>
          </Dialog.Close>

          <Button onClick={onConfirm} disabled={isMutationInFlight}>
            <FormattedMessage defaultMessage="Confirm" />
          </Button>
        </div>
      </div>

      {importError && (
        <p className="text-red-600">
          <FormattedMessage defaultMessage="There was an error while importing members, please try again..." />
        </p>
      )}
    </div>
  );
}

interface EventMemberInvite {
  username: string | null;
  email: string;
  team: string | null;
}

type CsvHeader = "unknown" | keyof EventMemberInvite;

interface CsvDataTableState {
  data: CsvData;
  headers: CsvHeader[];
  page: number;
}

interface CsvDataTableProps
  extends React.DetailedHTMLProps<
    React.TableHTMLAttributes<HTMLTableElement>,
    HTMLTableElement
  > {
  pageSize: number;
  state: CsvDataTableState;
  onChangeState: (state: CsvDataTableState) => void;
}

function CsvDataTable({
  state,
  onChangeState,
  pageSize,
  ...props
}: CsvDataTableProps) {
  const { data, headers, page } = state;
  const pageData = data.slice(page * pageSize, (page + 1) * pageSize);
  const numCells = Math.max(...data.map((row) => row.length));

  const knownHeaders: Array<SelectOption<CsvHeader>> = useMemo(
    () => [
      {
        value: "username",
        label: <FormattedMessage defaultMessage="Username" />,
      },
      { value: "email", label: <FormattedMessage defaultMessage="Email" /> },
      { value: "team", label: <FormattedMessage defaultMessage="Team" /> },
      {
        value: "unknown",
        label: <FormattedMessage defaultMessage="Unknown" />,
      },
    ],
    [],
  );

  const onDeleteRow =
    (index: number) => (_evt: React.MouseEvent<HTMLButtonElement>) => {
      const newData = data.slice();
      newData.splice(index, 1);
      onChangeState({
        ...state,
        data: newData,
      });
    };

  const onDeleteColumn =
    (index: number) => (_evt: React.MouseEvent<HTMLButtonElement>) => {
      const newHeaders = headers.slice();
      newHeaders.splice(index, 1);

      const newData = [];
      for (const row of state.data) {
        const newRow = row.slice();
        newRow.splice(index, 1);
        newData.push(newRow);
      }

      onChangeState({
        ...state,
        data: newData,
        headers: newHeaders,
      });
    };

  const onSwapHeader = (index: number, newHeader: CsvHeader) => {
    const newHeaders = headers.slice();

    const oldHeader = newHeaders[index]!;
    const prevIndex = newHeaders.indexOf(newHeader);
    newHeaders[index] = newHeader;
    if (prevIndex >= 0) {
      newHeaders[prevIndex] = oldHeader;
    }
    onChangeState({
      ...state,
      headers: newHeaders,
    });
  };

  return (
    <table {...props}>
      <thead>
        <tr>
          {Array.from(padArray(numCells, "unknown", headers)).map(
            (cell, index) => (
              <th key={index} className="bg-gray-100">
                <span className="inline-flex items-center">
                  <Select
                    className="p-3 bg-gray-100"
                    value={cell}
                    onValueChange={(newCell) => onSwapHeader(index, newCell)}
                    options={
                      // prevent user to remove the email column
                      cell !== "email"
                        ? knownHeaders
                        : knownHeaders.filter(
                            (option) => option.value !== "unknown",
                          )
                    }
                  />

                  {cell !== "email" && (
                    // prevent user to remove the email column
                    <TrashButton
                      className="py-2 px-3"
                      onClick={onDeleteColumn(index)}
                    />
                  )}
                </span>
              </th>
            ),
          )}

          <th className="p-3 bg-gray-100"></th>
        </tr>
      </thead>

      <tbody>
        {pageData.map((row, index) => (
          <tr
            key={index}
            className={cn(
              "hover:bg-indigo/5",
              index < pageData.length - 1 && "border-b-2",
            )}
          >
            {Array.from(padArray(numCells, "", row)).map((cell, index) => (
              <td key={index} className="whitespace-nowrap py-2 px-3 font-mono">
                {cell}
              </td>
            ))}

            <td>
              <TrashButton
                className="py-2 px-3"
                onClick={onDeleteRow(page * pageSize + index)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function TrashButton({
  className,
  ...props
}: React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>) {
  return (
    <button {...props} className={cn(className, "hover:text-indigo/50")}>
      <IoMdTrash />
    </button>
  );
}

interface SelectOption<X> {
  value: X;
  label: React.ReactNode;
}

interface SelectProps<X> {
  className?: string | undefined;
  value: X;
  options: Array<SelectOption<X>>;
  onValueChange: (value: X) => void;
}

function Select<X extends string>(props: SelectProps<X>) {
  const onChange: React.ChangeEventHandler<HTMLSelectElement> = (evt) => {
    props.onValueChange(evt.target.value as X);
  };
  return (
    <select
      className={cn(props.className, "cursor-pointer")}
      value={props.value}
      onChange={onChange}
    >
      {props.options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

interface PaginationProps {
  size: number;
  total: number;
  current: number;
  onCurrentChange: (current: number) => void;
}

function Pagination(props: PaginationProps) {
  const intl = useIntl();

  if (props.total < props.size) {
    return null;
  }

  const pages = Math.ceil(props.total / props.size);
  const hasPrevious = props.current > 0;
  const hasNext = props.current < pages - 1;

  return (
    <span className="inline-flex gap-1 items-center">
      {hasPrevious && (
        <button
          className="text-xl"
          title={intl.formatMessage({ defaultMessage: "Previous" })}
          onClick={() => props.onCurrentChange(props.current - 1)}
        >
          <MdArrowLeft />
        </button>
      )}

      <FormattedMessage
        defaultMessage="{current} of {pages}"
        values={{
          current: props.current + 1,
          pages,
        }}
      />

      {hasNext && (
        <button
          className="text-xl"
          title={intl.formatMessage({ defaultMessage: "Next" })}
          onClick={() => props.onCurrentChange(props.current + 1)}
        >
          <MdArrowRight />
        </button>
      )}
    </span>
  );
}

function promptFile(): Promise<File | null> {
  const input = document.createElement("input");
  input.type = "file";
  input.multiple = false;

  return new Promise((resolve, reject) => {
    input.addEventListener("change", () => {
      resolve(input.files?.item(0) ?? null);
    });
    input.addEventListener("cancel", () => {
      resolve(null);
    });
    input.addEventListener("error", (evt) => {
      reject(evt.error);
    });
    input.click();
  });
}

function readFileAsText(file: File): Promise<string> {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.addEventListener("load", () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      } else {
        reject(new Error("FileReader did not return text"));
      }
    });
    reader.addEventListener("abort", () => {
      reject(new Error("file read operation has been aborted"));
    });
    reader.addEventListener("error", () => {
      reject(reader.error);
    });
    reader.readAsText(file);
  });
}

function* padArray<X>(length: number, padding: X, xs: X[]) {
  yield* xs;
  for (let i = 0; i < length - xs.length; i++) {
    yield padding;
  }
}

function* transformCsvData(
  csv: CsvData,
  headers: CsvHeader[],
): Iterable<EventMemberInvite> {
  for (const row of csv) {
    yield {
      username: extractRowCell(row, headers, "username") || null,
      email: truthOrFail(
        // assert here because we made sure earlier users cannot remove this column
        extractRowCell(row, headers, "email"),
        "email is required",
      ),
      team: extractRowCell(row, headers, "team") || null,
    };
  }
}

function extractRowCell(
  row: string[],
  headers: CsvHeader[],
  header: CsvHeader,
): string | null {
  const index = headers.indexOf(header);
  if (index < 0) return null;
  return row[index];
}
