import { FaCheck, FaCopy } from "react-icons/fa6";
import { toast } from "sonner";
import { DetailedHTMLProps, HTMLAttributes, useState } from "react";
import { useIntl } from "react-intl";
import { cn } from "../utils/tailwind";

export interface CodeProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  nocopy?: boolean | undefined;
  content?: string;
}

export default function Code({
  nocopy = !navigator.clipboard,
  content,
  className,
  ...props
}: CodeProps) {
  const intl = useIntl();
  const [copied, setCopied] = useState(false);

  if (!content) {
    return null;
  }

  const onCopy = async (evt: React.MouseEvent) => {
    evt.preventDefault();
    await navigator.clipboard.writeText(content);
    setCopied(true);
    toast(
      intl.formatMessage({
        defaultMessage: "Code copied into clipboard!",
      }),
    );
  };

  return (
    <div {...props} className={cn(className, "flex items-center gap-2")}>
      <input
        className="flex-grow rounded bg-zinc-100 px-2 py-1 font-mono text-sm overflow-hidden overflow-x-auto w-full focus:outline-none focus:ring-2  focus:border-blue-500"
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
        aria-label={content}
        value={content}
        onFocus={(e) => e.target.select()}
        type="text"
        readOnly
        aria-readonly
      />

      {!nocopy && (
        <button
          className="cursor-pointer text-slate-700 hover:text-blue-600 active:bg-zinc-100 py-[0.38rem] px-2 rounded"
          onClick={onCopy}
        >
          {copied ? <FaCheck /> : <FaCopy />}
        </button>
      )}
    </div>
  );
}
