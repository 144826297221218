import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { EventInvitePageQuery as EventInvitePageQueryType } from "./__generated__/EventInvitePageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { EventInvitePageQuery as EventInvitePageQueryType } from "./__generated__/EventInvitePageQuery.graphql";

export const EventInvitePageQuery = graphql`
  query EventInvitePageQuery($id: ID!) {
    viewer @ifAllowed {
      id
    }
    node(id: $id) {
      ... on EventInvite {
        id
        title
        shortDescription
        thumbnail
        latestRuleText
        event @ifAllowed {
          id
          slug
        }
      }
    }
  }
`;

export const eventInvitePageQueryLoader = (environment: Environment) => {
  return ({ params: { id } }: LoaderArgs) => {
    if (!id) {
      throw new Response("No id", { status: 500 });
    }
    return preload<EventInvitePageQueryType>(
      environment,
      EventInvitePageQuery,
      {
        id,
      },
    );
  };
};

export const useEventInvitePageQuery = () =>
  usePreloaded<EventInvitePageQueryType>();
