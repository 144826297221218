import Outlet from "../common/Outlet";
import { useCompetitionScaffoldQuery } from "./loaders/CompetitionScaffold";
import { Link } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import { FormattedMessage, useIntl } from "react-intl";
import EntityProfilePic from "../components/EntityProfilePic";
import NavItem from "../components/NavItem";
import AspectDiv from "../components/AspectDiv";
import defaultThumbnail from "../assets/background_16_9_white.svg";
import { CompetitionSetupButton } from "../components/CompetitionSetupButton";
import CompetitionJoinButton from "../components/CompetitionJoinButton";
import { ScrollableScaffoldNav } from "../components/ScrollableScaffoldNav";
import { useLocation } from "../utils/location";
import { MetaLayout } from "../common/MetaLayout";
import { useCompetitionMetaTags } from "../utils/useMetaTags";

export default function CompetitionScaffold() {
  const location = useLocation();
  const {
    query: { competitionBySlug: competition, viewer },
  } = useCompetitionScaffoldQuery();
  const intl = useIntl();
  const { metaTitle, metaDescription } = useCompetitionMetaTags(
    competition?.title,
  );
  if (!competition) {
    return (
      <ErrorPage status={404}>
        <FormattedMessage defaultMessage="Competition not found." />
        {!viewer && (
          <span>
            {" "}
            <Link
              className="text-blue-500"
              to={`/login?login_redirect=${encodeURIComponent(location.href)}`}
            >
              <FormattedMessage defaultMessage="Try logging in to view this page." />
            </Link>
          </span>
        )}
      </ErrorPage>
    );
  }

  const joinButton = (
    <CompetitionJoinButton competition={competition}>
      {!location.pathname.startsWith(
        `/competitions/${competition.slug}/code`,
      ) && <CompetitionSetupButton competition={competition} />}
    </CompetitionJoinButton>
  );

  return (
    <MetaLayout
      metaTitle={metaTitle}
      metaDescription={metaDescription}
      metaImageAlt={competition.thumbnail && competition.shortDescription}
      metaImageUrl={competition.thumbnail}
      hideTitleTemplate
    >
      <div className="pb-2">
        <AspectDiv
          className="rounded-t-lg bg-grey"
          style={{
            backgroundImage: `url(${defaultThumbnail})`,
            backgroundSize: "1000px",
          }}
          ratio={4.75}
        >
          {competition.banner && (
            <img
              src={competition.banner}
              className="w-full h-full rounded-t-lg object-cover object-center"
              alt={intl.formatMessage(
                { defaultMessage: "{competitionTitle} background cover" },
                { competitionTitle: competition.title },
              )}
            />
          )}
        </AspectDiv>
      </div>
      <div className="flex flex-row space-x-4">
        <div className="w-full">
          <h1 className="text-2xl font-bold font-poppins">
            {competition.title}
          </h1>
          <h2 className="text-xl">{competition.shortDescription}</h2>
          {competition.viewerCanEdit && (
            <Link to={`/competitions/${competition.slug}/edit`}>
              <FormattedMessage defaultMessage="Edit" />
            </Link>
          )}
        </div>
        <Link to={`/${competition.host.username}`}>
          <div className="flex flex-row items-center space-x-4 hover:cursor-pointer">
            <EntityProfilePic entity={competition.host} size="16" />
            <div>
              <p className="whitespace-nowrap">
                <FormattedMessage defaultMessage="Hosted by" />
              </p>
              <p>{competition.host.displayName}</p>
            </div>
          </div>
        </Link>
      </div>
      <div className="lg:hidden [&>*]:w-full [&>*]:sm:w-fit">{joinButton}</div>
      <ScrollableScaffoldNav>
        <div className="flex justify-between items-center w-full">
          <div className="flex items-center space-x-2">
            <NavItem
              to={`/competitions/${competition.slug}`}
              exact
              variant="secondary"
            >
              <FormattedMessage defaultMessage="Overview" />
            </NavItem>
            <NavItem
              to={`/competitions/${competition.slug}/data`}
              variant="secondary"
            >
              <FormattedMessage defaultMessage="Docs" />
            </NavItem>
            <NavItem
              to={`/competitions/${competition.slug}/code/template`}
              variant="secondary"
            >
              <FormattedMessage defaultMessage="Code" />
            </NavItem>
            <NavItem
              to={`/competitions/${competition.slug}/rules`}
              variant="secondary"
            >
              <FormattedMessage defaultMessage="Rules" />
            </NavItem>
            <NavItem
              to={`/competitions/${competition.slug}/discussions`}
              variant="secondary"
            >
              <FormattedMessage defaultMessage="Discussion" />
            </NavItem>
            {competition.viewerCanEditMembers && (
              <NavItem
                to={`/competitions/${competition.slug}/members`}
                variant="secondary"
              >
                <FormattedMessage defaultMessage="Members" />
              </NavItem>
            )}
            {competition.hasLeaderboard && (
              <NavItem
                to={`/competitions/${competition.slug}/leaderboard`}
                variant="secondary"
              >
                <FormattedMessage defaultMessage="Leaderboard" />
              </NavItem>
            )}
            {competition.requiresApproval && competition.viewerCanApprove && (
              <NavItem
                to={`/competitions/${competition.slug}/approval`}
                variant="secondary"
              >
                <FormattedMessage defaultMessage="Approval" />
              </NavItem>
            )}
          </div>
          <div className="hidden lg:flex flex-row gap-2 px-2">{joinButton}</div>
        </div>
      </ScrollableScaffoldNav>
      <Outlet />
    </MetaLayout>
  );
}
