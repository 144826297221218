/**
 * @generated SignedSource<<809444b16ed27136a9041efc10bdaa90>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationKind = "AWARD_BADGE" | "CONTENT_MENTIONED" | "CREATE_SUBMISSION" | "CREATE_TOPIC" | "PROMOTIONAL_NEWSLETTER" | "REPLY_COMMENT" | "REPLY_TOPIC" | "SYSTEM";
export type UserSettingsNotificationsPageMutation$variables = {
  notification: NotificationKind;
  subscribe: boolean;
};
export type UserSettingsNotificationsPageMutation$data = {
  readonly setUserNotificationSubscription: {
    readonly node: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"UserSettingsNotificationsPageFragment">;
    };
  };
};
export type UserSettingsNotificationsPageMutation = {
  response: UserSettingsNotificationsPageMutation$data;
  variables: UserSettingsNotificationsPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "notification"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subscribe"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "notification",
    "variableName": "notification"
  },
  {
    "kind": "Variable",
    "name": "subscribe",
    "variableName": "subscribe"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserSettingsNotificationsPageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserEdge",
        "kind": "LinkedField",
        "name": "setUserNotificationSubscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserSettingsNotificationsPageFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserSettingsNotificationsPageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserEdge",
        "kind": "LinkedField",
        "name": "setUserNotificationSubscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserNotifications",
                "kind": "LinkedField",
                "name": "notifications",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "enabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "disabled",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6021cdc25a044409d50bcfb842166136",
    "id": null,
    "metadata": {},
    "name": "UserSettingsNotificationsPageMutation",
    "operationKind": "mutation",
    "text": "mutation UserSettingsNotificationsPageMutation(\n  $notification: NotificationKind!\n  $subscribe: Boolean!\n) {\n  setUserNotificationSubscription(notification: $notification, subscribe: $subscribe) {\n    node {\n      id\n      ...UserSettingsNotificationsPageFragment\n    }\n  }\n}\n\nfragment UserSettingsNotificationsPageFragment on User {\n  id\n  notifications {\n    enabled\n    disabled\n  }\n}\n"
  }
};
})();

(node as any).hash = "7ce49547f41e10568888ac2d3c1627e6";

export default node;
