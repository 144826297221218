import { useState } from "react";
import { graphql, useFragment, useMutation } from "react-relay";
import { toast } from "sonner";
import { useIntl } from "react-intl";

import { useLocation } from "../utils/location";

import FormGroup from "./FormGroup";
import Code from "./Code";
import Button from "./Button";
import { MdDelete, MdRefresh } from "react-icons/md";
import { ConfirmDialog } from "./ConfirmDialog";

import { EventInviteCodeFragment$key } from "./__generated__/EventInviteCodeFragment.graphql";
import { EventInviteCodeGenerateMutation as EventInviteCodeGenerateMutationType } from "./__generated__/EventInviteCodeGenerateMutation.graphql";
import { EventInviteCodeRemoveMutation as EventInviteCodeRemoveMutationType } from "./__generated__/EventInviteCodeRemoveMutation.graphql";
import { FormattedMessage } from "react-intl";

const EventInviteCodeFragment = graphql`
  fragment EventInviteCodeFragment on Event {
    invite {
      id
      code @ifAllowed
      viewerCanManage: viewerCan(action: MANAGE_EVENT_INVITE_CODE)
    }
  }
`;

const EventInviteCodeGenerateMutation = graphql`
  mutation EventInviteCodeGenerateMutation($id: ID!) {
    generateEventInviteCode(id: $id) {
      id
      code
    }
  }
`;

const EventInviteCodeRemoveMutation = graphql`
  mutation EventInviteCodeRemoveMutation($id: ID!) {
    removeEventInviteCode(id: $id) {
      id
      code
    }
  }
`;

interface Props {
  event: EventInviteCodeFragment$key;
}

export default function EventInviteCode({ event: eventFragment }: Props) {
  const intl = useIntl();
  const { origin } = useLocation();
  const { invite } = useFragment(EventInviteCodeFragment, eventFragment);
  const [showDialog, setShowDialog] = useState<
    undefined | "generate" | "remove"
  >();
  const [commitGenerate, isGenerateInFlight] =
    useMutation<EventInviteCodeGenerateMutationType>(
      EventInviteCodeGenerateMutation,
    );
  const [commitRemove, isRemoveInFlight] =
    useMutation<EventInviteCodeRemoveMutationType>(
      EventInviteCodeRemoveMutation,
    );
  const generate = () =>
    commitGenerate({
      variables: { id: invite.id },
      onCompleted: () => {
        toast.success(
          intl.formatMessage({ defaultMessage: "Invite code generated" }),
        );
        setShowDialog(undefined);
      },
    });
  const remove = () =>
    commitRemove({
      variables: { id: invite.id },
      onCompleted: () => {
        toast.success(
          intl.formatMessage({ defaultMessage: "Invite code removed" }),
        );
        setShowDialog(undefined);
      },
    });
  if (!invite.viewerCanManage) {
    return null;
  }
  return (
    <div className="my-4">
      <FormGroup label="Invite Link">
        <div className="flex gap-2">
          {invite.code ? (
            <>
              <Code
                content={`${origin}/event-invite/${invite.id}/${invite.code}`}
                className="flex-1"
              />
              <ConfirmDialog
                open={showDialog === "remove"}
                onOpenChange={(open) =>
                  setShowDialog(open ? "remove" : undefined)
                }
                disabled={isRemoveInFlight}
                onConfirm={remove}
                TriggerIcon={MdDelete}
                kind="danger"
                title={
                  <FormattedMessage defaultMessage="Remove the invite code" />
                }
                buttonMessage={<FormattedMessage defaultMessage="Remove" />}
                message={
                  <FormattedMessage defaultMessage="By clicking on the button below, this will remove the invite code invalidating it." />
                }
              />
              <ConfirmDialog
                open={showDialog === "generate"}
                onOpenChange={(open) =>
                  setShowDialog(open ? "generate" : undefined)
                }
                disabled={isGenerateInFlight}
                onConfirm={generate}
                TriggerIcon={MdRefresh}
                kind="danger"
                title={
                  <FormattedMessage defaultMessage="Regenerate the invite code" />
                }
                buttonMessage={<FormattedMessage defaultMessage="Regenerate" />}
                message={
                  <FormattedMessage defaultMessage="By clicking on the button below, this will regenerate the invite code invalidating the old one." />
                }
              />
            </>
          ) : (
            <Button size="sm" onClick={generate} disabled={isGenerateInFlight}>
              <FormattedMessage defaultMessage="Generate invite code" />
            </Button>
          )}
        </div>
      </FormGroup>
    </div>
  );
}
