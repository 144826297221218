import { useState } from "react";
import {
  graphql,
  useFragment,
  useMutation,
  ConnectionHandler,
} from "react-relay";
import { MdReply } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import CommentEditForm from "./CommentEditForm";
import { CommentReplyFragment$key } from "./__generated__/CommentReplyFragment.graphql";
import { CommentReplyCreateCommentMutation as CommentReplyCreateCommentMutationType } from "./__generated__/CommentReplyCreateCommentMutation.graphql";
import { VotableOrder } from "../utils/votableOrder";
import { useAuth } from "../utils/auth";

const CommentReplyFragment = graphql`
  fragment CommentReplyFragment on Comment {
    id
    viewerCanReply: viewerCan(action: CREATE_COMMENT)
  }
`;

const CommentReplyCreateCommentMutation = graphql`
  mutation CommentReplyCreateCommentMutation(
    $commentId: ID!
    $input: CreateCommentInput!
    $connections: [ID!]!
  ) {
    createCommentForComment(commentId: $commentId, input: $input)
      @prependEdge(connections: $connections) {
      node {
        id
        numChildren
        ...CommentFragment @arguments(bottomLevel: true)
      }
    }
  }
`;

interface Props {
  comment: CommentReplyFragment$key;
  order?: VotableOrder;
  loadReplies?: () => void;
}

export default function CommentReply({
  comment: commentFragment,
  order,
  loadReplies,
}: Props) {
  const { userId } = useAuth();
  const comment = useFragment(CommentReplyFragment, commentFragment);
  const [commitMutation, isMutationInFlight] =
    useMutation<CommentReplyCreateCommentMutationType>(
      CommentReplyCreateCommentMutation,
    );
  const [isVisible, setIsVisible] = useState(false);
  if (!userId || !comment.viewerCanReply) {
    return null;
  }
  if (!isVisible) {
    return (
      <span
        onClick={() => {
          if (loadReplies) {
            loadReplies();
          }
          setIsVisible(true);
        }}
        className="flex flex-row items-center hover:cursor-pointer"
      >
        <span className="pr-2">
          <MdReply />
        </span>
        <FormattedMessage defaultMessage="Reply" />
      </span>
    );
  }
  return (
    <div className="pt-2 w-full">
      <CommentEditForm
        autoFocus={true}
        isDisabled={isMutationInFlight}
        onCancel={() => setIsVisible(false)}
        onSubmit={({ content, setFormError, resetForm }) => {
          commitMutation({
            variables: {
              commentId: comment.id,
              input: {
                content,
              },
              connections: [
                ConnectionHandler.getConnectionID(
                  comment.id,
                  "CommentCommentsFragment_children",
                  { order },
                ),
              ],
            },
            onError: (error) => {
              setFormError(error.message);
            },
            onCompleted: () => {
              resetForm();
              setIsVisible(false);
            },
          });
        }}
        submitLabel={<FormattedMessage defaultMessage="Comment" />}
      />
    </div>
  );
}
