import { graphql, useMutation, ConnectionHandler } from "react-relay";
import { useIntl, FormattedMessage } from "react-intl";
import { useNavigate, useLocation } from "react-router-dom";
import { useEventTopicCreatePageQuery } from "./loaders/EventTopicCreatePage";
import {
  EventTopicCreatePageMutation$variables,
  EventTopicCreatePageMutation as EventTopicCreatePageMutationType,
} from "./__generated__/EventTopicCreatePageMutation.graphql";
import ErrorPage from "./ErrorPage";
import TopicEditForm from "../components/TopicEditForm";
import { logger } from "../common/logger";
import {
  DEFAULT_VOTABLE_ORDER,
  TopicVotableOrderContext,
} from "../utils/votableOrder";
import { getTopicPath } from "../utils/routing";

const EventTopicCreatePageMutation = graphql`
  mutation EventTopicCreatePageMutation(
    $forumId: ID!
    $input: CreateTopicInput!
    $connections: [ID!]!
  ) {
    createTopic(forumId: $forumId, input: $input)
      @prependEdge(connections: $connections) {
      node {
        id
        title
        forum {
          slug
          owner {
            __typename
            slug
          }
        }
      }
    }
  }
`;

export default function EventTopicCreatePage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const { query } = useEventTopicCreatePageQuery();
  const event = query?.eventBySlug;
  const [commitMutation, isMutationInFlight] =
    useMutation<EventTopicCreatePageMutationType>(EventTopicCreatePageMutation);

  const { order } = location.state || {};

  if (!event) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Event not found",
        })}
      />
    );
  }

  return (
    <TopicVotableOrderContext.Provider value={order}>
      <div>
        <h1 className="text-xl font-bold pb-4 font-poppins">
          <FormattedMessage defaultMessage={"New Topic"} />
        </h1>
        <TopicEditForm
          onSubmit={({ data, setFormError }) => {
            const variables: EventTopicCreatePageMutation$variables = {
              forumId: event.forum.id,
              input: {
                title: data.title,
                url: data.url || undefined,
                description: data.description || undefined,
              },
              connections: [
                ConnectionHandler.getConnectionID(
                  event.forum.id,
                  "ForumTopicsFragment_topics",
                  { order: order || DEFAULT_VOTABLE_ORDER },
                ),
              ],
            };
            commitMutation({
              variables,
              onError: (error) => {
                logger.error(error);
                setFormError(
                  intl.formatMessage({
                    defaultMessage: "Could not create topic :(",
                  }),
                );
              },
              onCompleted: ({ createTopic: { node } }) => {
                navigate(getTopicPath(node));
              },
            });
          }}
          isDisabled={isMutationInFlight}
          submitLabel={<FormattedMessage defaultMessage="Create" />}
        />
      </div>
    </TopicVotableOrderContext.Provider>
  );
}
