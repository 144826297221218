import { Environment } from "react-relay";
import { RouteObject, Preloader } from "../utils/route";

import AuthScaffold from "../pages/AuthScaffold";
import { authScaffoldQueryLoader } from "../pages/loaders/AuthScaffold";
import CompetitionCreatePage from "../pages/CompetitionCreatePage";
import CompetitionDataPage from "../pages/CompetitionDataPage";
import CompetitionCodePage from "../pages/CompetitionCodePage";
import { competitionCodePageQueryLoader } from "../pages/loaders/CompetitionCodePage";
import CompetitionCodeTreePage from "../pages/CompetitionCodeTreePage";
import { competitionCodeTreePageQueryLoader } from "../pages/loaders/CompetitionCodeTreePage";
import CompetitionCodeBlobPage from "../pages/CompetitionCodeBlobPage";
import { competitionCodeBlobPageQueryLoader } from "../pages/loaders/CompetitionCodeBlobPage";
import CompetitionCodeReadmePage from "../pages/CompetitionCodeReadmePage";
import { competitionCodeReadmePageQueryLoader } from "../pages/loaders/CompetitionCodeReadmePage";
import CompetitionEditPage from "../pages/CompetitionEditPage";
import { competitionEditPageQueryLoader } from "../pages/loaders/CompetitionEditPage";
import { competitionLeaderboardPageQueryLoader } from "../pages/loaders/CompetitionLeaderboardPage";
import CompetitionLeaderboardPage from "../pages/CompetitionLeaderboardPage";
import { competitionOverviewPageQueryLoader } from "../pages/loaders/CompetitionOverviewPage";
import CompetitionOverviewPage from "../pages/CompetitionOverviewPage";
import { competitionRulesPageQueryLoader } from "../pages/loaders/CompetitionRulesPage";
import CompetitionRulesPage from "../pages/CompetitionRulesPage";
import CompetitionScaffold from "../pages/CompetitionScaffold";
import { competitionScaffoldQueryLoader } from "../pages/loaders/CompetitionScaffold";
import CompetitionTopicCreatePage from "../pages/CompetitionTopicCreatePage";
import { competitionTopicCreatePageQueryLoader } from "../pages/loaders/CompetitionTopicCreatePage";
import CompetitionMembersPage from "../pages/CompetitionMembersPage";
import { competitionMembersPageQueryLoader } from "../pages/loaders/CompetitionMembersPage";
import CompetitionTopicsPage from "../pages/CompetitionTopicsPage";
import { competitionTopicsPageQueryLoader } from "../pages/loaders/CompetitionTopicsPage";
import EntityEditPage from "../pages/EntityEditPage";
import EntityOverviewPage from "../pages/EntityOverviewPage";
import EntityScaffold from "../pages/EntityScaffold";
import ErrorPage from "../pages/ErrorPage";
import HomePage from "../pages/HomePage";
import { homePageQueryLoader } from "../pages/loaders/HomePage";
import CompetitionsPage from "../pages/CompetitionsPage";
import { competitionsPageQueryLoader } from "../pages/loaders/CompetitionsPage";
import EventsPage from "../pages/EventsPage";
import { eventsPageQueryLoader } from "../pages/loaders/EventsPage";
import LoginPage from "../pages/LoginPage";
import OrganizationCreatePage from "../pages/OrganizationCreatePage";
import OrganizationUsersPage from "../pages/OrganizationUsersPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import OAuth2AuthorizePage from "../pages/OAuth2AuthorizePage";
import Scaffold from "../pages/Scaffold";
import SendPasswordResetPage from "../pages/SendPasswordResetPage";
import SignupPage from "../pages/SignupPage";
import TopicEditPage from "../pages/TopicEditPage";
import { topicEditPageQueryLoader } from "../pages/loaders/TopicEditPage";
import TopicPage from "../pages/TopicPage";
import { topicPageQueryLoader } from "../pages/loaders/TopicPage";
import UserOrganizationsPage from "../pages/UserOrganizationsPage";
import { entityOverviewPageQueryLoader } from "../pages/loaders/EntityOverviewPage";
import { entityScaffoldQueryLoader } from "../pages/loaders/EntityScaffold";
import { organizationUsersPageQueryLoader } from "../pages/loaders/OrganizationUsersPage";
import { scaffoldQueryLoader } from "../pages/loaders/Scaffold";
import { userOrganizationsPageQueryLoader } from "../pages/loaders/UserOrganizationsPage";
import UserCommentsPage from "../pages/UserCommentsPage";
import { userCommentsPageQueryLoader } from "../pages/loaders/UserCommentsPage";
import UserTopicsPage from "../pages/UserTopicsPage";
import { userTopicsQueryPageLoader } from "../pages/loaders/UserTopicsPage";
import { entitySubmissionsPageQueryLoader } from "../pages/loaders/EntitySubmissionsPage";
import EntitySubmissionsPage from "../pages/EntitySubmissionsPage";
import JoinParisHackPage from "../pages/JoinParisHackPage";
import { joinParisHackPageQueryLoader } from "../pages/loaders/JoinParisHackPage";
import EventScaffold from "../pages/EventScaffold";
import { eventScaffoldQueryLoader } from "../pages/loaders/EventScaffold";
import EventOverviewPage from "../pages/EventOverviewPage";
import { eventOverviewPageQueryLoader } from "../pages/loaders/EventOverviewPage";
import EventEditPage from "../pages/EventEditPage";
import { eventEditPageQueryLoader } from "../pages/loaders/EventEditPage";
import EventCreatePage from "../pages/EventCreatePage";
import EventMembersPage from "../pages/EventMembersPage";
import { eventMembersPageQueryLoader } from "../pages/loaders/EventMembersPage";
import EventCompetitionsPage from "../pages/EventCompetitionsPage";
import { eventCompetitionsPageQueryLoader } from "../pages/loaders/EventCompetitionsPage";
import EventTimetablePage from "../pages/EventTimetablePage";
import { eventRulesPageQueryLoader } from "../pages/loaders/EventRulesPage";
import EventRulesPage from "../pages/EventRulesPage";
import EntitySubmissionCodePage from "../pages/EntitySubmissionCodePage";
import { entitySubmissionCodePageQueryLoader } from "../pages/loaders/EntitySubmissionCodePage";
import EntitySubmissionCodeReadmePage from "../pages/EntitySubmissionCodeReadmePage";
import EntitySubmissionCodeTreePage from "../pages/EntitySubmissionCodeTreePage";
import EntitySubmissionCodeBlobPage from "../pages/EntitySubmissionCodeBlobPage";
import { entitySubmissionCodeReadmePageQueryLoader } from "../pages/loaders/EntitySubmissionCodeReadmePage";
import { entitySubmissionCodeTreePageQueryLoader } from "../pages/loaders/EntitySubmissionCodeTreePage";
import { entitySubmissionCodeBlobPageQueryLoader } from "../pages/loaders/EntitySubmissionCodeBlobPage";
import { competitionCreatePageQueryLoader } from "../pages/loaders/CompetitionCreatePage";
import UserSettingsNotificationsPage from "../pages/UserSettingsNotificationsPage";
import { userSettingsNotificationsQueryLoader } from "../pages/loaders/UserSettingsNotificationsPage";
import UnsubscribePage from "../pages/UnsubscribePage";
import ForumsPage from "../pages/ForumsPage";
import { forumsPageQueryLoader } from "../pages/loaders/ForumsPage";
import ForumEditPage from "../pages/ForumEditPage";
import { forumEditPageQueryLoader } from "../pages/loaders/ForumEditPage";
import ForumCreatePage from "../pages/ForumCreatePage";
import ForumScaffold from "../pages/ForumScaffold";
import { forumScaffoldQueryLoader } from "../pages/loaders/ForumScaffold";
import ForumTopicsPage from "../pages/ForumTopicsPage";
import { forumTopicsPageQueryLoader } from "../pages/loaders/ForumTopicsPage";
import ForumTopicCreatePage from "../pages/ForumTopicCreatePage";
import { forumTopicCreatePageQueryLoader } from "../pages/loaders/ForumTopicCreatePage";
import EventTimetableEditPage from "../pages/EventTimetableEditPage";
import { eventTimetableEditPageQueryLoader } from "../pages/loaders/EventTimetableEditPage";
import { eventTimetablePageQueryLoader } from "../pages/loaders/EventTimetablePage";
import EventInvitePage from "../pages/EventInvitePage";
import { eventInvitePageQueryLoader } from "../pages/loaders/EventInvitePage";
import CommentPage from "../pages/CommentPage";
import { commentPageQueryLoader } from "../pages/loaders/CommentPage";
import CompetitionApprovalPage from "../pages/CompetitionApprovalPage";
import { competitionApprovalPageQueryLoader } from "../pages/loaders/CompetitionApprovalPage";
import EventTopicsPage from "../pages/EventTopicsPage";
import { eventTopicsPageQueryLoader } from "../pages/loaders/EventTopicsPage";
import EventTopicCreatePage from "../pages/EventTopicCreatePage";
import { eventTopicCreatePageQueryLoader } from "../pages/loaders/EventTopicCreatePage";
import messages from "./routesTranslation";
import GlobalLeaderboardPage from "../pages/GlobalLeaderboardPage";
import { globalLeaderboardPageQueryLoader } from "../pages/loaders/GlobalLeaderboardPage";

export default function routes(
  environment: Environment,
): RouteObject<Preloader>[] {
  return [
    {
      path: "/",
      element: <AuthScaffold />,
      loader: authScaffoldQueryLoader(environment),
      errorElement: <ErrorPage />,
      handle: { titleI18n: messages.login },
      children: [
        {
          path: "login",
          element: <LoginPage />,
          handle: { titleI18n: messages.login },
        },
        {
          path: "signup",
          element: <SignupPage />,
          handle: { titleI18n: messages.signup },
        },
        {
          path: "send-password-reset",
          element: <SendPasswordResetPage />,
          handle: { titleI18n: messages.sendPasswordReset },
        },
        {
          path: "reset-password/:secret",
          element: <ResetPasswordPage />,
          handle: { titleI18n: messages.resetPassword },
        },
      ],
    },
    {
      path: "/",
      element: <Scaffold />,
      loader: scaffoldQueryLoader(environment),
      errorElement: <ErrorPage />,
      handle: { titleI18n: messages.home },
      children: [
        {
          index: true,
          element: <HomePage />,
          loader: homePageQueryLoader(environment),
          handle: { titleI18n: messages.home },
        },
        {
          path: "competitions",
          element: <CompetitionsPage />,
          loader: competitionsPageQueryLoader(environment),
          handle: { titleI18n: messages.competitions },
        },
        {
          path: "competitions/new",
          element: <CompetitionCreatePage />,
          loader: competitionCreatePageQueryLoader(environment),
          handle: { titleI18n: messages.newCompetition },
        },
        {
          path: "competitions/:slug",
          element: <CompetitionScaffold />,
          loader: competitionScaffoldQueryLoader(environment),
          handle: { titleI18n: messages.competitionDetail },
          children: [
            {
              index: true,
              element: <CompetitionOverviewPage />,
              loader: competitionOverviewPageQueryLoader(environment),
              handle: { titleI18n: messages.competitionOverview },
            },
            {
              path: "data",
              element: <CompetitionDataPage />,
              handle: { titleI18n: messages.competitionData },
            },
            {
              path: "code/:kind",
              element: <CompetitionCodePage />,
              loader: competitionCodePageQueryLoader(environment),
              handle: { titleI18n: messages.competitionCode },
              children: [
                {
                  index: true,
                  element: <CompetitionCodeReadmePage />,
                  loader: competitionCodeReadmePageQueryLoader(environment),
                  handle: { titleI18n: messages.competitionCodeReadme },
                },
                {
                  path: "tree/*",
                  element: <CompetitionCodeTreePage />,
                  loader: competitionCodeTreePageQueryLoader(environment),
                  handle: { titleI18n: messages.competitionCodeTree },
                },
                {
                  path: "blob/*",
                  element: <CompetitionCodeBlobPage />,
                  loader: competitionCodeBlobPageQueryLoader(environment),
                  handle: { titleI18n: messages.competitionCodeBlob },
                },
              ],
            },
            {
              path: "rules",
              element: <CompetitionRulesPage />,
              loader: competitionRulesPageQueryLoader(environment),
              handle: { titleI18n: messages.competitionRules },
            },
            {
              path: "discussions",
              element: <CompetitionTopicsPage />,
              loader: competitionTopicsPageQueryLoader(environment),
              handle: { titleI18n: messages.competitionTopic },
            },
            {
              path: "leaderboard",
              element: <CompetitionLeaderboardPage />,
              loader: competitionLeaderboardPageQueryLoader(environment),
              handle: { titleI18n: messages.competitionLeaderboard },
            },
            {
              path: "members",
              element: <CompetitionMembersPage />,
              loader: competitionMembersPageQueryLoader(environment),
              handle: { titleI18n: messages.competitionMembers },
            },
            {
              path: "approval",
              element: <CompetitionApprovalPage />,
              loader: competitionApprovalPageQueryLoader(environment),
              handle: { titleI18n: messages.competitionApproval },
            },
            {
              path: "discussions/:topicId/:title?",
              element: <TopicPage />,
              loader: topicPageQueryLoader(environment),
              handle: { titleI18n: messages.competitionTopic },
            },
          ],
        },
        {
          path: "competitions/:slug/discussions/new",
          element: <CompetitionTopicCreatePage />,
          loader: competitionTopicCreatePageQueryLoader(environment),
          handle: { titleI18n: messages.newDiscussion },
        },
        {
          path: "competitions/:slug/edit",
          element: <CompetitionEditPage />,
          loader: competitionEditPageQueryLoader(environment),
          handle: { titleI18n: messages.editCompetition },
        },
        {
          path: "competitions/:slug/discussions/:topicId/edit",
          element: <TopicEditPage />,
          loader: topicEditPageQueryLoader(environment),
          handle: { titleI18n: messages.editDiscussion },
        },
        {
          path: "events",
          element: <EventsPage />,
          loader: eventsPageQueryLoader(environment),
          handle: { titleI18n: messages.events },
        },
        {
          path: "events/new",
          element: <EventCreatePage />,
          handle: { titleI18n: messages.newEvent },
        },
        {
          path: "event-invite/:id/:code",
          element: <EventInvitePage />,
          loader: eventInvitePageQueryLoader(environment),
          handle: { titleI18n: messages.eventInvite },
        },
        {
          path: "events/:slug",
          element: <EventScaffold />,
          loader: eventScaffoldQueryLoader(environment),
          handle: { titleI18n: messages.eventDetail },
          children: [
            {
              index: true,
              element: <EventOverviewPage />,
              loader: eventOverviewPageQueryLoader(environment),
              handle: { titleI18n: messages.eventOverview },
            },
            {
              path: "members",
              element: <EventMembersPage />,
              loader: eventMembersPageQueryLoader(environment),
              handle: { titleI18n: messages.eventMembers },
            },
            {
              path: "competitions",
              element: <EventCompetitionsPage />,
              loader: eventCompetitionsPageQueryLoader(environment),
              handle: { titleI18n: messages.eventCompetitions },
            },
            {
              path: "agenda",
              element: <EventTimetablePage />,
              loader: eventTimetablePageQueryLoader(environment),
              handle: { titleI18n: messages.eventAgenda },
            },
            {
              path: "discussions",
              element: <EventTopicsPage />,
              loader: eventTopicsPageQueryLoader(environment),
              handle: { titleI18n: messages.eventDiscussions },
            },
            {
              path: "rules",
              element: <EventRulesPage />,
              loader: eventRulesPageQueryLoader(environment),
              handle: { titleI18n: messages.eventRules },
            },
            {
              path: "discussions/:topicId/:title?",
              element: <TopicPage />,
              loader: topicPageQueryLoader(environment),
              handle: { titleI18n: messages.eventTopic },
            },
          ],
        },
        {
          path: "events/:slug/agenda/edit",
          element: <EventTimetableEditPage />,
          loader: eventTimetableEditPageQueryLoader(environment),
          handle: { titleI18n: messages.editEventAgenda },
        },
        {
          path: "events/:slug/edit",
          element: <EventEditPage />,
          loader: eventEditPageQueryLoader(environment),
          handle: { titleI18n: messages.editEvent },
        },
        {
          path: "events/:slug/discussions/new",
          element: <EventTopicCreatePage />,
          loader: eventTopicCreatePageQueryLoader(environment),
          handle: { titleI18n: messages.newDiscussion },
        },
        {
          path: "events/:slug/discussions/:topicId/edit",
          element: <TopicEditPage />,
          loader: topicEditPageQueryLoader(environment),
          handle: { titleI18n: messages.editDiscussion },
        },
        {
          path: "discussions",
          element: <ForumsPage />,
          loader: forumsPageQueryLoader(environment),
          handle: { titleI18n: messages.forums },
        },
        {
          path: "discussions/new",
          element: <ForumCreatePage />,
          handle: { titleI18n: messages.newForum },
        },
        {
          path: "discussions/:slug/edit",
          element: <ForumEditPage />,
          loader: forumEditPageQueryLoader(environment),
          handle: { titleI18n: messages.editForum },
        },
        {
          path: "discussions/:slug",
          element: <ForumScaffold />,
          loader: forumScaffoldQueryLoader(environment),
          handle: { titleI18n: messages.forumDetail },
          children: [
            {
              index: true,
              element: <ForumTopicsPage />,
              loader: forumTopicsPageQueryLoader(environment),
              handle: { titleI18n: messages.forumTopics },
            },
            {
              path: ":topicId/:title?",
              element: <TopicPage />,
              loader: topicPageQueryLoader(environment),
              handle: { titleI18n: messages.forumTopic },
            },
          ],
        },
        {
          path: "discussions/:slug/new",
          element: <ForumTopicCreatePage />,
          loader: forumTopicCreatePageQueryLoader(environment),
          handle: { titleI18n: messages.newForumTopic },
        },
        {
          path: "discussions/:slug/:topicId/edit",
          element: <TopicEditPage />,
          loader: topicEditPageQueryLoader(environment),
          handle: { titleI18n: messages.editForumTopic },
        },
        {
          path: "settings/notifications",
          element: <UserSettingsNotificationsPage />,
          loader: userSettingsNotificationsQueryLoader(environment),
          handle: { titleI18n: messages.settingsNotifications },
        },
        {
          path: ":username",
          element: <EntityScaffold />,
          loader: entityScaffoldQueryLoader(environment),
          handle: { titleI18n: messages.userProfile },
          children: [
            {
              index: true,
              element: <EntityOverviewPage />,
              loader: entityOverviewPageQueryLoader(environment),
              handle: { titleI18n: messages.userOverview },
            },
            {
              path: "organizations",
              element: <UserOrganizationsPage />,
              loader: userOrganizationsPageQueryLoader(environment),
              handle: { titleI18n: messages.userOrganizations },
            },
            {
              path: "users",
              element: <OrganizationUsersPage />,
              loader: organizationUsersPageQueryLoader(environment),
              handle: { titleI18n: messages.organizationUsers },
            },
            {
              path: "comments",
              element: <UserCommentsPage />,
              loader: userCommentsPageQueryLoader(environment),
              handle: { titleI18n: messages.userComments },
            },
            {
              path: "topics",
              element: <UserTopicsPage />,
              loader: userTopicsQueryPageLoader(environment),
              handle: { titleI18n: messages.userTopics },
            },
            {
              path: "submissions",
              handle: { titleI18n: messages.userSubmissions },
              children: [
                {
                  index: true,
                  element: <EntitySubmissionsPage />,
                  loader: entitySubmissionsPageQueryLoader(environment),
                  handle: { titleI18n: messages.userSubmissions },
                },
                {
                  path: ":slug/:version/code/:kind",
                  element: <EntitySubmissionCodePage />,
                  loader: entitySubmissionCodePageQueryLoader(environment),
                  handle: { titleI18n: messages.submissionCode },
                  children: [
                    {
                      index: true,
                      element: <EntitySubmissionCodeReadmePage />,
                      loader:
                        entitySubmissionCodeReadmePageQueryLoader(environment),
                      handle: { titleI18n: messages.submissionCodeReadme },
                    },
                    {
                      path: "tree/*",
                      element: <EntitySubmissionCodeTreePage />,
                      loader:
                        entitySubmissionCodeTreePageQueryLoader(environment),
                      handle: { titleI18n: messages.submissionCodeTree },
                    },
                    {
                      path: "blob/*",
                      element: <EntitySubmissionCodeBlobPage />,
                      loader:
                        entitySubmissionCodeBlobPageQueryLoader(environment),
                      handle: { titleI18n: messages.submissionCodeBlob },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: ":username/edit",
          element: <EntityEditPage />,
          handle: { titleI18n: messages.editProfile },
        },
        {
          path: "organization/new",
          element: <OrganizationCreatePage />,
          handle: { titleI18n: messages.newOrganization },
        },
        {
          path: "comments/:id",
          element: <CommentPage />,
          loader: commentPageQueryLoader(environment),
          handle: { titleI18n: messages.commentDetail },
        },
        {
          path: "leaderboard",
          element: <GlobalLeaderboardPage />,
          loader: globalLeaderboardPageQueryLoader(environment),
          handle: { titleI18n: messages.globalLeaderboard },
        },
      ],
    },
    {
      path: "/oauth2/authorize",
      element: <OAuth2AuthorizePage />,
      handle: { titleI18n: messages.oauth2Authorize },
    },
    {
      path: "/embed/join-paris-hack",
      element: <JoinParisHackPage />,
      loader: joinParisHackPageQueryLoader(environment),
      handle: { titleI18n: messages.joinParisHack },
    },
    {
      path: "/settings/notifications/unsubscribe",
      element: <UnsubscribePage />,
      handle: { titleI18n: messages.unsubscribe },
    },
  ];
}
