import { useFragment, graphql } from "react-relay";
import { EventTeamsFragment$key } from "./__generated__/EventTeamsFragment.graphql";
import { EventTeamsMembersFragment$key } from "./__generated__/EventTeamsMembersFragment.graphql";
import { EventTeamsEntityDetailFragment$key } from "./__generated__/EventTeamsEntityDetailFragment.graphql";
import { usePaginationFragment } from "react-relay";
import { FormattedPlural } from "react-intl";
import EntityProfilePic from "./EntityProfilePic";
import LoadMore from "./LoadMore";
import { Link } from "react-router-dom";

const EventTeamsEntityDetailFragment = graphql`
  fragment EventTeamsEntityDetailFragment on Entity {
    id
    username
    displayName
    ...EntityProfilePicFragment @arguments(thumbnail: true)
  }
`;

interface EventTeamsEntityDetailProps {
  size: number;
  entity: EventTeamsEntityDetailFragment$key;
}

function EventTeamsEntityDetail({
  size,
  entity: entityFragment,
}: EventTeamsEntityDetailProps) {
  const entity = useFragment(EventTeamsEntityDetailFragment, entityFragment);
  return (
    <Link to={`/${entity.username}`}>
      <div className="flex flex-col items-center max-w-28">
        <div className={`w-${size}`}>
          <EntityProfilePic entity={entity} />
        </div>
        <div className="text-center pt-2">{entity.displayName}</div>
      </div>
    </Link>
  );
}

const EventTeamsMembersFragment = graphql`
  fragment EventTeamsMembersFragment on Organization
  @refetchable(queryName: "EventTeamsMembersFragmentPaginationQuery")
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 10 }
  ) {
    users(first: $count, after: $cursor)
      @connection(key: "EventTeamsMembersFragment_users") {
      edges {
        node {
          id
          user {
            ...EventTeamsEntityDetailFragment
          }
        }
      }
    }
  }
`;

interface EventTeamsMembersProps {
  organization: EventTeamsMembersFragment$key;
}

function EventTeamsMembers({
  organization: organizationFragment,
}: EventTeamsMembersProps) {
  const {
    data: organization,
    loadNext,
    hasNext,
    isLoadingNext,
  } = usePaginationFragment(EventTeamsMembersFragment, organizationFragment);
  return (
    <div className="w-full flex flex-row flex-nowrap overflow-x-auto space-x-6 pr-4 py-2">
      {organization.users.edges.map(({ node: orgUser }) => (
        <EventTeamsEntityDetail
          key={orgUser.id}
          entity={orgUser.user}
          size={16}
        />
      ))}
      <LoadMore
        loadMore={loadNext}
        isLoading={isLoadingNext}
        hasMore={hasNext}
      />
    </div>
  );
}

const EventTeamsFragment = graphql`
  fragment EventTeamsFragment on Event
  @refetchable(queryName: "EventTeamsFragmentPaginationQuery")
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 3 }
  ) {
    id
    isPrivate
    teams(first: $count, after: $cursor)
      @connection(key: "EventTeamsFragment_teams") {
      edges {
        node {
          id
          kind
          ...EventTeamsEntityDetailFragment
          ...EntityProfilePicFragment @arguments(thumbnail: true)
          ...EventTeamsMembersFragment
        }
      }
    }
  }
`;

interface Props {
  event: EventTeamsFragment$key;
}

export default function EventTeams({ event: eventFragment }: Props) {
  const {
    data: event,
    loadNext,
    hasNext,
    isLoadingNext,
  } = usePaginationFragment(EventTeamsFragment, eventFragment);
  if (!event.isPrivate) {
    return null;
  }
  return (
    <div className="space-y-4">
      <h2 className="text-xl font-bold font-poppins pt-8 pb-2">
        <FormattedPlural
          value={event.teams.edges.length}
          one="Your Team"
          other="Your Teams"
        />
      </h2>
      {event.teams.edges.map(({ node }) => (
        <div className="flex flex-row px-2 space-x-6" key={node.id}>
          <div className="flex-shrink-0">
            <EventTeamsEntityDetail entity={node} size={20} />
          </div>
          <div className="border-l border-solid flex-shrink-0" />
          <EventTeamsMembers organization={node} />
        </div>
      ))}
      <LoadMore
        loadMore={loadNext}
        isLoading={isLoadingNext}
        hasMore={hasNext}
      />
    </div>
  );
}
