import { FormattedMessage, useIntl } from "react-intl";
import EventMembers from "../components/EventMembers";
import { useEventMembersPageQuery } from "./loaders/EventMembersPage";
import ErrorPage from "./ErrorPage";
import EventMembersEditForm from "../components/EventMembersEditForm";
import EventMembersImport from "../components/EventMembersImport";
import Button from "../components/Button";
import { MdArrowDropDown, MdCloudUpload, MdTableRows } from "react-icons/md";
import Dropdown from "../components/Dropdown";
import EventInvitations from "../components/EventInvitations";
import { useState } from "react";

export default function EventMembersPage() {
  const intl = useIntl();
  const [currentDialog, setCurrentDialog] = useState<
    null | "invitations" | "csv_import"
  >(null);
  const { query } = useEventMembersPageQuery();
  const event = query.eventBySlug;
  if (!event) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Event not found",
        })}
      />
    );
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="flex justify-between">
        <Dropdown
          autoCloses
          trigger={
            <Button
              kind="text"
              className="flex gap-1 items-center hover:text-indigo hover:bg-black/5"
            >
              <FormattedMessage
                defaultMessage="{invitations, plural, one {1 pending invitation} other {# pending invitations}}"
                values={{ invitations: event.invitationsCount }}
              />
              <MdArrowDropDown />
            </Button>
          }
        >
          <Dropdown.Item
            iconEnd={<MdTableRows />}
            onClick={() => setCurrentDialog("invitations")}
          >
            <FormattedMessage defaultMessage="Review invitations" />
          </Dropdown.Item>

          <Dropdown.Item
            iconEnd={<MdCloudUpload />}
            onClick={() => setCurrentDialog("csv_import")}
          >
            <FormattedMessage defaultMessage="Load CSV file" />
          </Dropdown.Item>
        </Dropdown>

        <EventMembersEditForm event={event} autocomplete={query} />
      </div>

      <EventInvitations.Dialog
        event={event}
        open={currentDialog === "invitations"}
        onOpenChange={(open) => setCurrentDialog(open ? "invitations" : null)}
      />

      <EventMembersImport
        event={event}
        open={currentDialog === "csv_import"}
        onOpenChange={(open) => setCurrentDialog(open ? "csv_import" : null)}
      />

      <EventMembers user={event} />
    </div>
  );
}
