import { useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import FormGroup from "../components/FormGroup";
import TextInput from "../components/TextInput";
import TextArea from "../components/TextArea";
import Button from "../components/Button";
import { useUpload, useImagePreview } from "../utils/hooks";
import { maxFileSize } from "../utils/validation";
import AspectDiv from "../components/AspectDiv";
import defaultThumbnail from "../assets/background_16_9_white.svg";
import Switch from "../components/Switch";
import { useLocation } from "../utils/location";
import { CompetitionTagInputForCompetitionEditPageFragment$key } from "./__generated__/CompetitionTagInputForCompetitionEditPageFragment.graphql";
import { CompetitionTagInput } from "./CompetitionTagInput";
import { CompetitionTagInputFragment$key } from "./__generated__/CompetitionTagInputFragment.graphql";
import SlugInput from "./SlugInput";
import ActivityVisibilityInput, {
  ActivityVisibility,
} from "./ActivityVisibilityInput";

type FormData = {
  title: string;
  shortDescription: string;
  slug: string;
  banner: FileList;
  thumbnail: FileList;
  visibility: ActivityVisibility;
  requiresApproval: boolean;
  grantHostSubmissionAccess: boolean;
  hasLeaderboard: boolean;
  tagIds: string[];
};

interface DefaultValues {
  title?: string;
  shortDescription?: string;
  slug?: string;
  banner?: string;
  thumbnail?: string;
  visibility?: ActivityVisibility;
  requiresApproval?: boolean;
  grantHostSubmissionAccess?: boolean;
  hasLeaderboard?: boolean;
  currentTags?: CompetitionTagInputForCompetitionEditPageFragment$key;
}

interface SubmitData {
  data: FormData;
  banner: { variable: null | undefined; uploadable: File | undefined };
  thumbnail: { variable: null | undefined; uploadable: File | undefined };
  dirtyFields: Partial<
    Readonly<Record<keyof Omit<FormData, "tagIds">, boolean | undefined>>
  >;
  setFormError: React.Dispatch<React.SetStateAction<string | undefined>>;
}
interface Props {
  onSubmit: (data: SubmitData) => void;
  isDisabled?: boolean;
  autoSlug?: boolean;
  routePrefix: string;
  availableTags?: CompetitionTagInputFragment$key;
  defaultValues?: DefaultValues;
  canUpdateAccess?: boolean;
}

export default function CompetitionEditForm({
  onSubmit,
  isDisabled = false,
  autoSlug,
  routePrefix,
  availableTags,
  defaultValues,
  canUpdateAccess,
}: Props) {
  const location = useLocation();
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    resetField,
    control,
    watch,
    formState: { errors, dirtyFields },
  } = useForm<FormData>();
  const [formError, setFormError] = useState<string | undefined>(undefined);

  const banner = useUpload(defaultValues?.banner, watch("banner"), () => {
    resetField("banner");
  });
  const bannerPreview = useImagePreview(banner.value);
  const thumbnail = useUpload(
    defaultValues?.thumbnail,
    watch("thumbnail"),
    () => {
      resetField("thumbnail");
    },
  );
  const thumbnailPreview = useImagePreview(thumbnail.value);
  const doSubmit = handleSubmit((data) => {
    setFormError(undefined);
    onSubmit({
      data,
      dirtyFields,
      banner: { variable: banner.variable, uploadable: banner.value },
      thumbnail: {
        variable: thumbnail.variable,
        uploadable: thumbnail.value,
      },
      setFormError,
    });
  });
  const errorMessages = {
    title: {
      required: intl.formatMessage({
        defaultMessage: "Title is required",
      }),
      maxLength: intl.formatMessage({
        defaultMessage: "Title cannot be longer than 50 characters",
      }),
    },
    shortDescription: {
      required: intl.formatMessage({
        defaultMessage: "Description is required",
      }),
      maxLength: intl.formatMessage({
        defaultMessage: "Description cannot be longer than 255 characters",
      }),
    },
    slug: {
      required: intl.formatMessage({
        defaultMessage: "URL is required",
      }),
      maxLength: intl.formatMessage({
        defaultMessage: "URL cannot be longer than 35 characters",
      }),
      pattern: intl.formatMessage({
        defaultMessage: "URL is invalid",
      }),
    },
    visibility: {},
    requiresApproval: {},
    grantHostSubmissionAccess: {},
    hasLeaderboard: {},
    banner: {
      size: intl.formatMessage({
        defaultMessage: "Image must be at most 2 MB",
      }),
    },
    thumbnail: {
      size: intl.formatMessage({
        defaultMessage: "Image must be at most 2 MB",
      }),
    },
  };
  return (
    <form onSubmit={doSubmit}>
      <div className="space-y-6">
        {formError && <p className="pt-1 text-sm text-red-500">{formError}</p>}
        <div className="flex flex-col space-y-3">
          <FormGroup
            label={intl.formatMessage({ defaultMessage: "Title" })}
            error={
              typeof errors.title?.type === "string" &&
              errorMessages.title[
                errors.title.type as keyof typeof errorMessages.title
              ]
            }
          >
            <TextInput
              aria-invalid={errors.title ? "true" : "false"}
              defaultValue={defaultValues?.title}
              {...register("title", {
                setValueAs: (value) => value.trim(),
                required: true,
                maxLength: 50,
              })}
            />
          </FormGroup>
          <FormGroup
            label={intl.formatMessage({ defaultMessage: "Short Description" })}
            error={
              typeof errors.shortDescription?.type === "string" &&
              errorMessages.shortDescription[
                errors.shortDescription
                  .type as keyof typeof errorMessages.shortDescription
              ]
            }
          >
            <TextArea
              aria-invalid={errors.shortDescription ? "true" : "false"}
              defaultValue={defaultValues?.shortDescription}
              {...register("shortDescription", {
                setValueAs: (value) => value.trim(),
                required: true,
                maxLength: 255,
              })}
            />
          </FormGroup>
          {availableTags && (
            <FormGroup label={intl.formatMessage({ defaultMessage: "Tags" })}>
              <CompetitionTagInput
                name="tagIds"
                control={control}
                tags={availableTags}
                defaultTags={defaultValues?.currentTags}
              />
            </FormGroup>
          )}
          <FormGroup
            label={intl.formatMessage({ defaultMessage: "URL" })}
            error={
              typeof errors.slug?.type === "string" &&
              errorMessages.slug[
                errors.slug.type as keyof typeof errorMessages.slug
              ]
            }
          >
            <SlugInput
              control={control}
              name={"slug"}
              defaultValue={defaultValues?.slug}
              watchName={"title"}
              watchDefaultValue={defaultValues?.title}
              prefix={`${location.host}/${routePrefix}/`}
              auto={autoSlug}
              rules={{ required: true }}
            />
          </FormGroup>
          <FormGroup
            label={intl.formatMessage({ defaultMessage: "Visibility" })}
            error={
              typeof errors.visibility?.type === "string" &&
              errorMessages.visibility[
                errors.visibility.type as keyof typeof errorMessages.visibility
              ]
            }
            inline
          >
            <ActivityVisibilityInput
              {...register("visibility")}
              defaultValue={defaultValues?.visibility}
            />
          </FormGroup>
          <FormGroup
            label={intl.formatMessage({ defaultMessage: "Requires Approval" })}
            error={
              typeof errors.requiresApproval?.type === "string" &&
              errorMessages.requiresApproval[
                errors.requiresApproval
                  .type as keyof typeof errorMessages.requiresApproval
              ]
            }
            inline
          >
            <Switch
              name="requiresApproval"
              control={control}
              defaultValue={defaultValues?.requiresApproval ?? false}
            />
          </FormGroup>
          {canUpdateAccess && (
            <FormGroup
              label={intl.formatMessage({
                defaultMessage: "Grant host submission access",
              })}
              error={
                typeof errors.grantHostSubmissionAccess?.type === "string" &&
                errorMessages.grantHostSubmissionAccess[
                  errors.grantHostSubmissionAccess
                    .type as keyof typeof errorMessages.grantHostSubmissionAccess
                ]
              }
              inline
            >
              <Switch
                name="grantHostSubmissionAccess"
                control={control}
                defaultValue={defaultValues?.grantHostSubmissionAccess ?? false}
              />
            </FormGroup>
          )}
          <FormGroup
            label={intl.formatMessage({ defaultMessage: "Has Leaderboard" })}
            error={
              typeof errors.hasLeaderboard?.type === "string" &&
              errorMessages.hasLeaderboard[
                errors.hasLeaderboard
                  .type as keyof typeof errorMessages.hasLeaderboard
              ]
            }
            inline
          >
            <Switch
              name="hasLeaderboard"
              control={control}
              defaultValue={defaultValues?.hasLeaderboard ?? true}
            />
          </FormGroup>
          <FormGroup
            label={intl.formatMessage({ defaultMessage: "Banner" })}
            error={
              typeof errors.banner?.type === "string" &&
              errorMessages.banner[
                errors.banner.type as keyof typeof errorMessages.banner
              ]
            }
          >
            <div className="pb-4">
              <AspectDiv
                className="rounded-lg bg-grey"
                style={{
                  backgroundImage: `url(${defaultThumbnail})`,
                  backgroundSize: "1000px",
                }}
                ratio={4.75}
              >
                {banner.isDirty
                  ? bannerPreview && (
                      <img
                        src={bannerPreview}
                        className="h-full w-full rounded-lg object-cover object-center"
                      />
                    )
                  : defaultValues?.banner && (
                      <img
                        src={defaultValues.banner}
                        className="h-full w-full rounded-lg object-cover object-center"
                      />
                    )}
              </AspectDiv>
            </div>
            <div>
              <input
                type="file"
                accept="image/png, image/jpeg"
                aria-invalid={errors.banner ? "true" : "false"}
                {...register("banner", {
                  validate: {
                    size: maxFileSize(1024 * 1024 * 2), // 2 MB
                  },
                })}
              />
              <div className="flex flex-row">
                {banner.isDirty && (
                  <Button kind="text" onClick={banner.reset}>
                    <FormattedMessage defaultMessage="Reset" />
                  </Button>
                )}
                {banner.canDelete && (
                  <Button kind="text" onClick={banner.deleteImage}>
                    <FormattedMessage defaultMessage="Delete" />
                  </Button>
                )}
              </div>
            </div>
          </FormGroup>

          <FormGroup
            label={intl.formatMessage({ defaultMessage: "Thumbnail" })}
            error={
              typeof errors.thumbnail?.type === "string" &&
              errorMessages.thumbnail[
                errors.thumbnail.type as keyof typeof errorMessages.thumbnail
              ]
            }
          >
            <div className="pb-4 w-64">
              <AspectDiv
                className="rounded-lg bg-grey"
                style={{
                  backgroundImage: `url(${defaultThumbnail})`,
                  backgroundSize: "1000px",
                }}
                ratio={1}
              >
                {thumbnail.isDirty
                  ? thumbnailPreview && (
                      <img
                        src={thumbnailPreview}
                        className="h-full w-full rounded-lg object-cover object-center"
                      />
                    )
                  : defaultValues?.thumbnail && (
                      <img
                        src={defaultValues.thumbnail}
                        className="h-full w-full rounded-lg object-cover object-center"
                      />
                    )}
              </AspectDiv>
            </div>
            <div>
              <input
                type="file"
                accept="image/png, image/jpeg"
                aria-invalid={errors.thumbnail ? "true" : "false"}
                {...register("thumbnail", {
                  validate: {
                    size: maxFileSize(1024 * 1024 * 2), // 2 MB
                  },
                })}
              />
              <div className="flex flex-row">
                {thumbnail.isDirty && (
                  <Button kind="text" onClick={thumbnail.reset}>
                    <FormattedMessage defaultMessage="Reset" />
                  </Button>
                )}
                {thumbnail.canDelete && (
                  <Button kind="text" onClick={thumbnail.deleteImage}>
                    <FormattedMessage defaultMessage="Delete" />
                  </Button>
                )}
              </div>
            </div>
          </FormGroup>
          <div className="pt-2 pb-8">
            <Button kind="primary" onClick={doSubmit} disabled={isDisabled}>
              <FormattedMessage defaultMessage="Save" />
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
