import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactNode, useRef, useState } from "react";
import MarkdownEditor from "./MarkdownEditor";
import Button from "./Button";
import type {
  MarkdownEditorContextProps,
  MarkdownValue,
} from "./MarkdownEditor/context";
import { MarkdownEditorProvider } from "./MarkdownEditor/Provider";
import { graphql } from "relay-runtime";
import { useLazyLoadQuery } from "react-relay";
import { CommentEditFormQuery as CommentEditFormQueryType } from "./__generated__/CommentEditFormQuery.graphql";

const CommentEditFormQuery = graphql`
  query CommentEditFormQuery {
    ...MemberSelectAllEntitiesAutoCompleteFragment
  }
`;

type FormData = {
  content: string;
};

interface SubmitData {
  content: string;
  setFormError: React.Dispatch<React.SetStateAction<string | undefined>>;
  resetForm: () => void;
}

interface Props {
  content?: string;
  onSubmit: (data: SubmitData) => void;
  onCancel?: () => void;
  isDisabled?: boolean;
  autoFocus?: boolean;
  submitLabel: ReactNode;
}

export default function CommentEditForm({
  onSubmit,
  onCancel,
  isDisabled = false,
  autoFocus,
  content,
  submitLabel,
}: Props) {
  const query = useLazyLoadQuery<CommentEditFormQueryType>(
    CommentEditFormQuery,
    {},
  );
  const intl = useIntl();
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, dirtyFields },
  } = useForm<FormData>();
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const [markdownValue, setMarkdownValue] = useState<MarkdownValue>(content);
  const resetTextareaRef = useRef<() => void>(() => {});

  const resetForm = () => {
    reset();
    setFormError(undefined);
    setMarkdownValue(undefined);
    resetTextareaRef.current();
  };
  const errorMessages = {
    required: intl.formatMessage({
      defaultMessage: "Comment is required",
    }),
    maxLength: intl.formatMessage({
      defaultMessage: "Comment cannot be longer than 1000 characters",
    }),
  };
  const doSubmit = handleSubmit((data: FormData) => {
    if (content && !dirtyFields.content) {
      if (onCancel) {
        onCancel();
      }
      return;
    }
    setFormError(undefined);
    onSubmit({ content: data.content, setFormError, resetForm });
  });
  const error =
    formError ||
    (typeof errors.content?.type === "string" &&
      errorMessages[errors.content.type as keyof typeof errorMessages]);

  const contextValue: MarkdownEditorContextProps<FormData> = {
    name: "content",
    control,
    defaultValue: content,
    setMarkdownValue,
    markdownValue,
    canUploadFiles: true,
    members: {
      kind: "all",
      members: query,
    },
  };

  return (
    <MarkdownEditorProvider value={contextValue}>
      <form onSubmit={doSubmit}>
        <MarkdownEditor
          rows={5}
          autoFocus={autoFocus}
          aria-invalid={errors.content ? "true" : "false"}
          resetTextarea={(refFunc) => (resetTextareaRef.current = refFunc)}
        />
        {error && <p className="text-red-500">{error}</p>}
        <div className="flex flex-row space-x-4 py-2 justify-end">
          {onCancel && (
            <Button kind="text" disabled={isDisabled} onClick={onCancel}>
              <FormattedMessage defaultMessage="Cancel" />
            </Button>
          )}
          <Button kind="primary" disabled={isDisabled} onClick={doSubmit}>
            {submitLabel}
          </Button>
        </div>
      </form>
    </MarkdownEditorProvider>
  );
}
