/**
 * @generated SignedSource<<7914dcd1b6d66c3092277b2df97eeced>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventInvitationsFragment$data = {
  readonly id: string;
  readonly invitations: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly email: string;
        readonly id: string;
        readonly organization: {
          readonly displayName: string;
          readonly username: string;
          readonly " $fragmentSpreads": FragmentRefs<"EntityProfilePicFragment">;
        } | null | undefined;
        readonly registeredAs: {
          readonly displayName: string;
          readonly username: string;
          readonly " $fragmentSpreads": FragmentRefs<"EntityProfilePicFragment">;
        } | null | undefined;
        readonly username: string | null | undefined;
      };
    }>;
  };
  readonly " $fragmentType": "EventInvitationsFragment";
};
export type EventInvitationsFragment$key = {
  readonly " $data"?: EventInvitationsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventInvitationsFragment">;
};

import EventInvitationsFragmentPaginationQuery_graphql from './EventInvitationsFragmentPaginationQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "invitations"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  },
  {
    "args": [
      {
        "kind": "Literal",
        "name": "thumbnail",
        "value": true
      }
    ],
    "kind": "FragmentSpread",
    "name": "EntityProfilePicFragment"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": EventInvitationsFragmentPaginationQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "EventInvitationsFragment",
  "selections": [
    {
      "alias": "invitations",
      "args": null,
      "concreteType": "EventInvitationConnection",
      "kind": "LinkedField",
      "name": "__EventInvitationsFragment_invitations_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EventInvitationEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EventInvitation",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Organization",
                  "kind": "LinkedField",
                  "name": "organization",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "registeredAs",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "dd4037101d29dd12422aaabdf3a818b2";

export default node;
