import { FormattedMessage, useIntl } from "react-intl";
import { graphql } from "relay-runtime";
import { useMutation } from "react-relay";
import { useForm } from "react-hook-form";
import {
  useUserSettingsNotificationsFragment,
  useUserSettingsNotificationsQuery,
} from "./loaders/UserSettingsNotificationsPage";
import { NotificationKind } from "./loaders/__generated__/UserSettingsNotificationsPageFragment.graphql";
import { toast } from "sonner";
import Switch from "../components/Switch";
import {
  formatNotificationToast,
  notificationLabels,
} from "../utils/notifications";
import { UserSettingsNotificationsPageMutation } from "./__generated__/UserSettingsNotificationsPageMutation.graphql";
import Button from "../components/Button";
import { UserSettingsNotificationsPageUnsubscribeFromAllMutation } from "./__generated__/UserSettingsNotificationsPageUnsubscribeFromAllMutation.graphql";
import { MdNotificationsNone } from "react-icons/md";
import { logger } from "../common/logger";
import { OptionToggleCard } from "../components/OptionToggleCard";
import { Navigate } from "react-router-dom";

type UserSettingsNotificationsForm = {
  [K in NotificationKind]?: boolean | undefined;
};

const Mutation = graphql`
  mutation UserSettingsNotificationsPageMutation(
    $notification: NotificationKind!
    $subscribe: Boolean!
  ) {
    setUserNotificationSubscription(
      notification: $notification
      subscribe: $subscribe
    ) {
      node {
        id
        ...UserSettingsNotificationsPageFragment
      }
    }
  }
`;

const UnsubscribeFromAllMutation = graphql`
  mutation UserSettingsNotificationsPageUnsubscribeFromAllMutation {
    unsubscribeFromAllNotifications {
      node {
        id
        ...UserSettingsNotificationsPageFragment
      }
    }
  }
`;

export default function UserSettingsNotificationsPage() {
  const {
    query: { viewer },
  } = useUserSettingsNotificationsQuery();

  if (viewer) {
    return <ActualPage />;
  } else {
    return (
      <Navigate
        to={`/login?login_redirect=${encodeURIComponent("settings/notifications")}`}
      />
    );
  }
}

function ActualPage() {
  const intl = useIntl();

  const {
    query: { viewer },
  } = useUserSettingsNotificationsQuery();

  const fragment = useUserSettingsNotificationsFragment(viewer);
  const enabled = new Set(fragment.notifications.enabled);
  const notifications = fragment.notifications.enabled.concat(
    fragment.notifications.disabled,
  );
  notifications.sort();

  const form = useForm<UserSettingsNotificationsForm>({
    values: notifications.reduce(
      (acc, x) => ({ ...acc, [x]: enabled.has(x) }),
      {},
    ),
  });

  const [commitMutation, isUnsubscribing] =
    useMutation<UserSettingsNotificationsPageMutation>(Mutation);

  const [unsubscribeFromAll, isUnsubscribingFromAll] =
    useMutation<UserSettingsNotificationsPageUnsubscribeFromAllMutation>(
      UnsubscribeFromAllMutation,
    );

  const isCommiting = isUnsubscribing || isUnsubscribingFromAll;

  const onNotificationUpdated = (
    notification: NotificationKind,
    subscribe: boolean,
    errors: unknown[] | null,
  ) => {
    if (!errors?.length) {
      toast(formatNotificationToast(intl, notification, subscribe));
    } else {
      logger.error(errors);
      toast.error(
        intl.formatMessage({
          defaultMessage:
            "There was an error with your request, please try again",
        }),
      );
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-2">
        <span className="text-xl">
          <MdNotificationsNone />
        </span>
        <h4 className="text-lg font-semibold">
          <FormattedMessage defaultMessage="We will send you an email:" />
        </h4>
      </div>

      <div className="flex flex-col gap-4 md:mx-16">
        {notifications.map((notification) => {
          const label = notificationLabels[notification];
          return (
            <OptionToggleCard
              key={notification}
              title={intl.formatMessage(label.title)}
              description={intl.formatMessage(label.description)}
            >
              <Switch
                control={form.control}
                disabled={isCommiting}
                {...form.register(notification, {
                  onChange: ({ target: { value: subscribe } }) => {
                    commitMutation({
                      variables: {
                        notification,
                        subscribe,
                      },
                      onCompleted: (_, errors) =>
                        onNotificationUpdated(notification, subscribe, errors),
                    });
                  },
                })}
              />
            </OptionToggleCard>
          );
        })}

        <Button
          onClick={() => unsubscribeFromAll({ variables: {} })}
          disabled={isCommiting}
        >
          <FormattedMessage defaultMessage="Unsubscribe from all" />
        </Button>
      </div>
    </div>
  );
}
