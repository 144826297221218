import { graphql } from "relay-runtime";
import { FormattedMessage } from "react-intl";
import { usePaginationFragment } from "react-relay";
import Button from "./Button";
import * as Dialog from "./Dialog";
import { EventInvitationsFragment$key } from "./__generated__/EventInvitationsFragment.graphql";
import { Link } from "react-router-dom";
import EntityProfilePic from "./EntityProfilePic";

const Fragment = graphql`
  fragment EventInvitationsFragment on Event
  @refetchable(queryName: "EventInvitationsFragmentPaginationQuery")
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 10 }
  ) {
    invitations(first: $count, after: $cursor)
      @connection(key: "EventInvitationsFragment_invitations") {
      edges {
        node {
          id
          username
          email
          organization {
            username
            displayName
            ...EntityProfilePicFragment @arguments(thumbnail: true)
          }
          registeredAs {
            username
            displayName
            ...EntityProfilePicFragment @arguments(thumbnail: true)
          }
        }
      }
    }
  }
`;

export interface EventInvitationsProps {
  event: EventInvitationsFragment$key;
}

export default function EventInvitations(props: EventInvitationsProps) {
  const pageSize = 10;
  const { data, hasNext, loadNext, isLoadingNext } = usePaginationFragment(
    Fragment,
    props.event,
  );
  return (
    <>
      <table className="table-auto overflow-x-auto w-full">
        <thead>
          <tr>
            <th className="py-3 px-3 bg-gray-100">
              <FormattedMessage defaultMessage="Participant" />
            </th>
            <th className="py-3 px-3 bg-gray-100">
              <FormattedMessage defaultMessage="Team" />
            </th>
          </tr>
        </thead>

        <tbody>
          {data.invitations.edges.map(({ node }) => (
            <tr key={node.id}>
              <td className="whitespace-nowrap py-2 px-3">
                {node.registeredAs ? (
                  <Link to={`/${node.registeredAs.username}`}>
                    <div className="flex flex-row items-center">
                      <div className="w-12 pr-4">
                        <EntityProfilePic entity={node.registeredAs} />
                      </div>
                      <span>{node.registeredAs.displayName}</span>
                    </div>
                  </Link>
                ) : (
                  <a href={`mailto:${node.email}`}>
                    {node.username ?? node.email}
                  </a>
                )}
              </td>
              <td className="whitespace-nowrap py-2 px-3">
                {node.organization && (
                  <Link to={`/${node.organization.username}`}>
                    <div className="flex flex-row items-center">
                      <div className="w-12 pr-4">
                        <EntityProfilePic entity={node.organization} />
                      </div>
                      <span>{node.organization.displayName}</span>
                    </div>
                  </Link>
                )}
              </td>
            </tr>
          ))}
          {!data.invitations.edges.length && (
            <tr>
              <td colSpan={2}>
                <p className="text-center italic py-2 px-3">
                  <FormattedMessage defaultMessage="All invited users have registered!" />
                </p>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {hasNext && (
        <Button
          kind="text"
          onClick={() => loadNext(pageSize)}
          disabled={isLoadingNext}
        >
          <FormattedMessage defaultMessage="Load more" />
        </Button>
      )}
    </>
  );
}

export interface EventInvitationsDialogProps extends EventInvitationsProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

function EventInvitationsDialog({
  open,
  onOpenChange,
  ...props
}: EventInvitationsDialogProps) {
  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Content className="lg:min-w-[600px] max-h-[75vh]">
          <div className="flex flex-row items-center px-4 md:py-2 py-4">
            <Dialog.Title className="flex-1">
              <FormattedMessage defaultMessage="Review invitations" />
            </Dialog.Title>
            <Dialog.Close />
          </div>

          <div className="flex-1 overflow-y-auto">
            <EventInvitations {...props} />
          </div>

          <Dialog.Close.Mobile className="mt-8 w-full px-3 py-2">
            <Button kind="secondary" className="w-full">
              <FormattedMessage defaultMessage="Close" />
            </Button>
          </Dialog.Close.Mobile>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

EventInvitations.Dialog = EventInvitationsDialog;
