import { forwardRef } from "react";
import { cn } from "../utils/tailwind";

export type NoteColor = "default" | "blue" | "red" | "green" | "yellow";

export interface NoteProps
  extends Omit<React.HTMLAttributes<HTMLSpanElement>, "prefix"> {
  color?: NoteColor | undefined;
  prefix?: React.ReactNode | undefined;
  icon?: React.ReactNode | undefined;
  children?: React.ReactNode | undefined;
  onClick?: React.MouseEventHandler | undefined;
}

export default forwardRef<HTMLSpanElement, NoteProps>(function Note(
  { color = "default", prefix, icon, children, className, ...props },
  ref,
) {
  return (
    <span
      ref={ref}
      {...props}
      className={cn(
        "px-2 py-1 rounded-lg flex flex-row items-center gap-1",
        noteClassNames(color, typeof props.onClick !== "undefined"),
        typeof props.onClick !== "undefined" && "cursor-pointer",
        className,
      )}
    >
      {icon && <span>{icon}</span>}
      {prefix && <span>{prefix}</span>}
      {children && <span>{children}</span>}
    </span>
  );
});

function noteClassNames(color: NoteColor, isClickable: boolean) {
  switch (color) {
    case "default":
      return ["bg-gray-100", isClickable && "hover:bg-gray-200"];
    case "blue":
      return ["bg-blue-50 text-blue-500", isClickable && "hover:bg-blue-100"];
    case "red":
      return ["bg-red-50 text-red-500", isClickable && "hover:bg-red-100"];
    case "green":
      return [
        "bg-green-50 text-green-600",
        isClickable && "hover:bg-green-200",
      ];
    case "yellow":
      return [
        "bg-yellow-50 text-yellow-600",
        isClickable && "hover:bg-yellow-200",
      ];
  }
}
