import { defineMessages } from "react-intl";

const messages = defineMessages({
  // AuthScaffold routes
  login: {
    id: "login",
    defaultMessage: "Login",
  },
  signup: {
    id: "signup",
    defaultMessage: "Sign Up",
  },
  sendPasswordReset: {
    id: "sendPasswordReset",
    defaultMessage: "Send Password Reset",
  },
  resetPassword: {
    id: "resetPassword",
    defaultMessage: "Reset Password",
  },

  // Home and competition-related routes
  home: {
    id: "home",
    defaultMessage: "Home",
  },
  competitions: {
    id: "competitions",
    defaultMessage: "Competitions",
  },
  newCompetition: {
    id: "newCompetition",
    defaultMessage: "New Competition",
  },
  competitionDetail: {
    id: "competitionDetail",
    defaultMessage: "Competition Details",
  },
  competitionOverview: {
    id: "competitionOverview",
    defaultMessage: "Competition Overview",
  },
  competitionData: {
    id: "competitionData",
    defaultMessage: "Competition Docs",
  },
  competitionCode: {
    id: "competitionCode",
    defaultMessage: "Competition Code",
  },
  competitionCodeReadme: {
    id: "competitionCodeReadme",
    defaultMessage: "Code Readme",
  },
  competitionCodeTree: {
    id: "competitionCodeTree",
    defaultMessage: "Code Tree",
  },
  competitionCodeBlob: {
    id: "competitionCodeBlob",
    defaultMessage: "Code Blob",
  },
  competitionRules: {
    id: "competitionRules",
    defaultMessage: "Competition Rules",
  },
  competitionDiscussions: {
    id: "competitionDiscussions",
    defaultMessage: "Competition Discussions",
  },
  competitionLeaderboard: {
    id: "competitionLeaderboard",
    defaultMessage: "Competition Leaderboard",
  },
  competitionMembers: {
    id: "competitionMembers",
    defaultMessage: "Competition Members",
  },
  competitionApproval: {
    id: "competitionApproval",
    defaultMessage: "Competition Approval",
  },
  competitionTopic: {
    id: "competitionTopic",
    defaultMessage: "Competition Discussion",
  },
  newDiscussion: {
    id: "newDiscussion",
    defaultMessage: "New Discussion",
  },
  editCompetition: {
    id: "editCompetition",
    defaultMessage: "Edit Competition",
  },
  editDiscussion: {
    id: "editDiscussion",
    defaultMessage: "Edit Discussion",
  },

  // Event-related routes
  events: {
    id: "events",
    defaultMessage: "Events",
  },
  newEvent: {
    id: "newEvent",
    defaultMessage: "New Event",
  },
  eventInvite: {
    id: "eventInvite",
    defaultMessage: "Join Event",
  },
  eventDetail: {
    id: "eventDetail",
    defaultMessage: "Event Details",
  },
  eventOverview: {
    id: "eventOverview",
    defaultMessage: "Event Overview",
  },
  eventMembers: {
    id: "eventMembers",
    defaultMessage: "Event Members",
  },
  eventCompetitions: {
    id: "eventCompetitions",
    defaultMessage: "Event Competitions",
  },
  eventAgenda: {
    id: "eventAgenda",
    defaultMessage: "Event Agenda",
  },
  editEventAgenda: {
    id: "editEventAgenda",
    defaultMessage: "Edit Event Agenda",
  },
  editEvent: {
    id: "editEvent",
    defaultMessage: "Edit Event",
  },
  eventDiscussions: {
    id: "eventDiscussions",
    defaultMessage: "Event Discussions",
  },
  eventRules: {
    id: "eventRules",
    defaultMessage: "Event Rules",
  },
  eventTopic: {
    id: "eventTopic",
    defaultMessage: "Event Discussion",
  },

  // Forum and discussion-related routes
  forums: {
    id: "forums",
    defaultMessage: "Discussions",
  },
  newForum: {
    id: "newForum",
    defaultMessage: "New Discussion",
  },
  editForum: {
    id: "editForum",
    defaultMessage: "Edit Discussion",
  },
  forumDetail: {
    id: "forumDetail",
    defaultMessage: "Discussion Details",
  },
  forumTopics: {
    id: "forumTopics",
    defaultMessage: "Discussion Topics",
  },
  forumTopic: {
    id: "forumTopic",
    defaultMessage: "Discussion Topic",
  },
  newForumTopic: {
    id: "newForumTopic",
    defaultMessage: "New Discussion Topic",
  },
  editForumTopic: {
    id: "editForumTopic",
    defaultMessage: "Edit Discussion Topic",
  },

  // User and organization-related routes
  settingsNotifications: {
    id: "settingsNotifications",
    defaultMessage: "Notification Settings",
  },
  userProfile: {
    id: "userProfile",
    defaultMessage: "Profile",
  },
  userOverview: {
    id: "userOverview",
    defaultMessage: "Overview",
  },
  userOrganizations: {
    id: "userOrganizations",
    defaultMessage: "Organizations",
  },
  organizationUsers: {
    id: "organizationUsers",
    defaultMessage: "Organization Users",
  },
  userComments: {
    id: "userComments",
    defaultMessage: "Comments",
  },
  userTopics: {
    id: "userTopics",
    defaultMessage: "Discussions",
  },
  userSubmissions: {
    id: "userSubmissions",
    defaultMessage: "Submissions",
  },
  submissionCode: {
    id: "submissionCode",
    defaultMessage: "Submission Code",
  },
  submissionCodeReadme: {
    id: "submissionCodeReadme",
    defaultMessage: "Submission Code Readme",
  },
  submissionCodeTree: {
    id: "submissionCodeTree",
    defaultMessage: "Submission Code Tree",
  },
  submissionCodeBlob: {
    id: "submissionCodeBlob",
    defaultMessage: "Submission Code Blob",
  },
  editProfile: {
    id: "editProfile",
    defaultMessage: "Edit Profile",
  },
  newOrganization: {
    id: "newOrganization",
    defaultMessage: "New Organization",
  },

  // Comments and misc
  commentDetail: {
    id: "commentDetail",
    defaultMessage: "Comment Details",
  },
  oauth2Authorize: {
    id: "oauth2Authorize",
    defaultMessage: "OAuth2 Authorization",
  },
  joinParisHack: {
    id: "joinParisHack",
    defaultMessage: "Join Paris Hack",
  },
  unsubscribe: {
    id: "unsubscribe",
    defaultMessage: "Unsubscribe from Notifications",
  },
  globalLeaderboard: {
    id: "globalLeaderboard",
    defaultMessage: "Leaderboard",
  },
  default: {
    id: "default",
    defaultMessage: "Page",
  },
});

export type RoutesTranslation = typeof messages;

export type RoutesTranslationKeys = keyof RoutesTranslation;

export type RoutesTranslationIds =
  RoutesTranslation[RoutesTranslationKeys]["id"];

export default messages;
