/**
 * @generated SignedSource<<468f8430476608c5c9fa04b7816bd39b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActivityVisibility = "AUTHENTICATED" | "MEMBERS" | "UNAUTHENTICATED";
export type EventEditPageQuery$variables = {
  slug: string;
};
export type EventEditPageQuery$data = {
  readonly eventBySlug: {
    readonly banner: any | null | undefined;
    readonly id: string;
    readonly shortDescription: string;
    readonly slug: string;
    readonly thumbnail: any | null | undefined;
    readonly title: string;
    readonly viewerCanUpdateAgenda: boolean;
    readonly visibility: ActivityVisibility;
    readonly " $fragmentSpreads": FragmentRefs<"EventInviteCodeFragment">;
  } | null | undefined;
};
export type EventEditPageQuery = {
  response: EventEditPageQuery$data;
  variables: EventEditPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortDescription",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "banner",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnail",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visibility",
  "storageKey": null
},
v9 = {
  "alias": "viewerCanUpdateAgenda",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "UPDATE_AGENDA"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"UPDATE_AGENDA\")"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventEditPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "eventBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EventInviteCodeFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventEditPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "eventBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "EventInvite",
            "kind": "LinkedField",
            "name": "invite",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              {
                "alias": "viewerCanManage",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "action",
                    "value": "MANAGE_EVENT_INVITE_CODE"
                  }
                ],
                "kind": "ScalarField",
                "name": "viewerCan",
                "storageKey": "viewerCan(action:\"MANAGE_EVENT_INVITE_CODE\")"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a83fbd3d3d03b0273511ae09e1407acc",
    "id": null,
    "metadata": {},
    "name": "EventEditPageQuery",
    "operationKind": "query",
    "text": "query EventEditPageQuery(\n  $slug: String!\n) {\n  eventBySlug(slug: $slug) {\n    id\n    slug\n    title\n    shortDescription\n    banner\n    thumbnail\n    visibility\n    viewerCanUpdateAgenda: viewerCan(action: UPDATE_AGENDA)\n    ...EventInviteCodeFragment\n  }\n}\n\nfragment EventInviteCodeFragment on Event {\n  invite {\n    id\n    code @ifAllowed\n    viewerCanManage: viewerCan(action: MANAGE_EVENT_INVITE_CODE)\n  }\n}\n"
  }
};
})();

(node as any).hash = "50c451c2468051fab54655ead7a5f4a2";

export default node;
