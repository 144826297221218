import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { EventEditPageQuery as EventEditPageQueryType } from "./__generated__/EventEditPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { EventEditPageQuery as EventEditPageQueryType } from "./__generated__/EventEditPageQuery.graphql";

export const EventEditPageQuery = graphql`
  query EventEditPageQuery($slug: String!) {
    eventBySlug(slug: $slug) {
      id
      slug
      title
      shortDescription
      banner
      thumbnail
      visibility
      viewerCanUpdateAgenda: viewerCan(action: UPDATE_AGENDA)
      ...EventInviteCodeFragment
    }
  }
`;

export const eventEditPageQueryLoader = (environment: Environment) => {
  return ({ params: { slug } }: LoaderArgs) => {
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    return preload<EventEditPageQueryType>(environment, EventEditPageQuery, {
      slug,
    });
  };
};

export const useEventEditPageQuery = () =>
  usePreloaded<EventEditPageQueryType>();
