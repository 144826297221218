import { LightAsync as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomOneLight as codeStyle } from "react-syntax-highlighter/dist/cjs/styles/hljs";

export interface CodeViewerProps {
  language: string;
  noNumbers?: boolean | undefined;
  className?: string | undefined;
  children: string;
}

export default function CodeViewer({
  language,
  noNumbers,
  className = "rounded-md",
  children,
}: CodeViewerProps) {
  return (
    <SyntaxHighlighter
      PreTag="pre"
      children={children.replace(/\n$/, "")}
      language={language}
      className={className}
      style={codeStyle}
      showLineNumbers={!noNumbers}
      wrapLines
      wrapLongLines
    />
  );
}
