/**
 * @generated SignedSource<<8056d29220fba721bdf6a15288899e76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventMembersEditFormAddMutation$variables = {
  connections: ReadonlyArray<string>;
  entityId: string;
  eventId: string;
};
export type EventMembersEditFormAddMutation$data = {
  readonly addEventMember: {
    readonly node: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"EventMembersEventMembershipFragment">;
    };
  };
};
export type EventMembersEditFormAddMutation = {
  response: EventMembersEditFormAddMutation$data;
  variables: EventMembersEditFormAddMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entityId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "eventId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "entityId",
    "variableName": "entityId"
  },
  {
    "kind": "Variable",
    "name": "eventId",
    "variableName": "eventId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EventMembersEditFormAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "EventMembershipEdge",
        "kind": "LinkedField",
        "name": "addEventMember",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EventMembership",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EventMembersEventMembershipFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EventMembersEditFormAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "EventMembershipEdge",
        "kind": "LinkedField",
        "name": "addEventMember",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EventMembership",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "kind",
                "storageKey": null
              },
              {
                "alias": "viewerCanDelete",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "action",
                    "value": "REMOVE_EVENT_MEMBER"
                  }
                ],
                "kind": "ScalarField",
                "name": "viewerCan",
                "storageKey": "viewerCan(action:\"REMOVE_EVENT_MEMBER\")"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "entity",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "username",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isEntity"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "imageThumbnail",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "latest",
                    "value": true
                  }
                ],
                "concreteType": "EventRuleAgreementConnection",
                "kind": "LinkedField",
                "name": "ruleAgreements",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EventRuleAgreement",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "ruleAgreements(latest:true)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": null,
        "handle": "prependEdge",
        "key": "",
        "kind": "LinkedHandle",
        "name": "addEventMember",
        "handleArgs": [
          {
            "kind": "Variable",
            "name": "connections",
            "variableName": "connections"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "ff49ae85d11048aeb99f3e34d531c241",
    "id": null,
    "metadata": {},
    "name": "EventMembersEditFormAddMutation",
    "operationKind": "mutation",
    "text": "mutation EventMembersEditFormAddMutation(\n  $eventId: ID!\n  $entityId: ID!\n) {\n  addEventMember(eventId: $eventId, entityId: $entityId) {\n    node {\n      id\n      ...EventMembersEventMembershipFragment\n    }\n  }\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  displayName\n  imageThumbnail\n}\n\nfragment EventMembersEventMembershipFragment on EventMembership {\n  id\n  kind\n  viewerCanDelete: viewerCan(action: REMOVE_EVENT_MEMBER)\n  entity {\n    __typename\n    id\n    username\n    displayName\n    ...EntityProfilePicFragment_49kWkb\n  }\n  ruleAgreements(latest: true) @ifAllowed {\n    nodes {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "13eb8089dd599f4bfac567fd044b94fc";

export default node;
