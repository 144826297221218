import { ConnectionHandler, graphql, useMutation } from "react-relay";
import { CompetitionMembersFragment$key } from "./__generated__/CompetitionMembersFragment.graphql";
import { CompetitionMembersDeleteMutation as CompetitionMembersDeleteMutationType } from "./__generated__/CompetitionMembersDeleteMutation.graphql";
import { CompetitionMembersPageTransferOwnershipMutation as CompetitionMembersPageTransferOwnershipMutationType } from "./__generated__/CompetitionMembersPageTransferOwnershipMutation.graphql";
import { usePaginationFragment } from "react-relay";
import { FormattedMessage, useIntl } from "react-intl";
import EntityProfilePic from "./EntityProfilePic";
import { MdMoreHoriz } from "react-icons/md";
import Dropdown from "./Dropdown";
import { formatCompetitionMembershipKind } from "../utils/format";
import { useAuth } from "../utils/auth";
import LoadMore from "./LoadMore";
import { Link } from "react-router-dom";
import { ToolbarButton } from "./Button";
import Note from "./Note";
import { logger } from "../common/logger";

const CompetitionMembersFragment = graphql`
  fragment CompetitionMembersFragment on Competition
  @refetchable(queryName: "CompetitionMembersFragmentPaginationQuery")
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 10 }
  ) {
    id
    viewerCanEdit: viewerCan(action: UPDATE_COMPETITION)
    viewerCanEditMembers: viewerCan(action: ADD_COMPETITION_MEMBER)
    viewerCanTransferOwnership: viewerCan(
      action: TRANSFER_COMPETITION_OWNERSHIP
    )
    canReadAgreement: viewerCan(action: READ_COMPETITION_RULE_AGREEMENT)
    members(first: $count, after: $cursor)
      @connection(key: "CompetitionMembersFragment_members") {
      edges {
        node {
          id
          kind
          viewerCanDelete: viewerCan(action: REMOVE_COMPETITION_MEMBER)
          entity {
            id
            username
            displayName
            ...EntityProfilePicFragment @arguments(thumbnail: true)
          }
          ruleAgreements(latest: true) @ifAllowed {
            nodes {
              id
            }
          }
        }
      }
    }
  }
`;

const CompetitionMembersPageTransferOwnershipMutation = graphql`
  mutation CompetitionMembersPageTransferOwnershipMutation(
    $competitionId: ID!
    $toUserId: ID!
    $connections: [ID!]!
  ) {
    transferCompetitionOwnership(
      competitionId: $competitionId
      toEntityId: $toUserId
    ) @prependEdge(connections: $connections) {
      node {
        id
        kind
        viewerCanDelete: viewerCan(action: REMOVE_COMPETITION_MEMBER)
        competition {
          id
          viewerCanEdit: viewerCan(action: UPDATE_COMPETITION)
          viewerCanEditMembers: viewerCan(action: ADD_COMPETITION_MEMBER)
          viewerCanTransferOwnership: viewerCan(
            action: TRANSFER_COMPETITION_OWNERSHIP
          )
          host {
            id
            displayName
            username
            ...EntityProfilePicFragment @arguments(thumbnail: true)
          }
        }
        entity {
          id
          ...EntityProfilePicFragment @arguments(thumbnail: true)
        }
      }
    }
  }
`;

const CompetitionMembersDeleteMutation = graphql`
  mutation CompetitionMembersDeleteMutation($id: ID!, $connections: [ID!]!) {
    removeCompetitionMember(id: $id) @deleteEdge(connections: $connections)
  }
`;

interface Props {
  user: CompetitionMembersFragment$key;
}

export default function CompetitionMembers({ user: userFragment }: Props) {
  const { userId: viewerId } = useAuth();
  const intl = useIntl();
  const {
    data: competition,
    refetch,
    loadNext,
    hasNext,
    isLoadingNext,
  } = usePaginationFragment(CompetitionMembersFragment, userFragment);
  const [commitDeleteMutation, isDeleteMutationInFlight] =
    useMutation<CompetitionMembersDeleteMutationType>(
      CompetitionMembersDeleteMutation,
    );
  const [commitTransferOwnershipMutation, isTransferOwnershipMutationInFlight] =
    useMutation<CompetitionMembersPageTransferOwnershipMutationType>(
      CompetitionMembersPageTransferOwnershipMutation,
    );
  if (competition.members.edges.length == 0) {
    return (
      <p className="text-gray-400">
        <FormattedMessage defaultMessage="No Users" />
      </p>
    );
  }
  const onDelete = (id: string, userId: string) => {
    commitDeleteMutation({
      variables: {
        id,
        connections: [
          ConnectionHandler.getConnectionID(
            competition.id,
            "CompetitionMembersFragment_members",
          ),
        ],
      },
      onError: (error) => {
        logger.error(error);
      },
      onCompleted: () => {
        if (userId === viewerId) {
          refetch({});
        }
      },
    });
  };
  const onTransferOwnershipSubmit = (userId: string) => {
    commitTransferOwnershipMutation({
      variables: {
        competitionId: competition.id,
        toUserId: userId,
        connections: [
          ConnectionHandler.getConnectionID(
            competition.id,
            "CompetitionMembersFragment_members",
          ),
        ],
      },
      updater: (store) => {
        const record = store.get(competition.id);
        if (!record) {
          return;
        }
        const connection = ConnectionHandler.getConnection(
          record,
          "CompetitionMembersFragment_members",
        );
        if (!connection) {
          return;
        }
        const oldParticipantId = connection
          .getLinkedRecords("edges")
          ?.find((edge) => {
            const node = edge?.getLinkedRecord("node");
            return (
              node?.getValue("kind") === "PARTICIPANT" &&
              node?.getLinkedRecord("entity")?.getValue("id") === userId
            );
          })
          ?.getLinkedRecord("node")
          ?.getValue("id");
        if (typeof oldParticipantId === "string") {
          ConnectionHandler.deleteNode(connection, oldParticipantId);
        }
      },
      onError: (error) => {
        logger.error(error);
      },
    });
  };
  return (
    <div className="overflow-x-auto">
      <table className="table-auto w-full">
        <thead>
          <tr>
            <th className="py-3 px-3 bg-gray-100">
              <FormattedMessage defaultMessage="User" />
            </th>
            <th className="py-3 px-3 bg-gray-100">
              <FormattedMessage defaultMessage="Membership" />
            </th>
            {competition.canReadAgreement && (
              <th className="py-3 px-3 bg-gray-100">
                <FormattedMessage defaultMessage="Rules" />
              </th>
            )}
            <th className="py-3 px-3 bg-gray-100"></th>
          </tr>
        </thead>
        <tbody>
          {competition.members.edges.map(({ node: membership }) => (
            <tr key={membership.id}>
              <td className="whitespace-nowrap py-2 px-3">
                <Link to={`/${membership.entity.username}`}>
                  <div className="flex flex-row items-center hover:cursor-pointer">
                    <div className="pr-4">
                      <EntityProfilePic entity={membership.entity} size="12" />
                    </div>
                    <span>{membership.entity.displayName}</span>
                  </div>
                </Link>
              </td>
              <td className="whitespace-nowrap py-2 px-3 text-center">
                {formatCompetitionMembershipKind(intl, membership.kind)}
              </td>
              {competition.canReadAgreement && (
                <td className="whitespace-nowrap py-2 px-3 text-center">
                  {membership.ruleAgreements?.nodes.length > 0 ? (
                    <Note color="green" className="inline-block">
                      <FormattedMessage defaultMessage="Accepted" />
                    </Note>
                  ) : (
                    <Note color="yellow" className="inline-block">
                      <FormattedMessage defaultMessage="Not Accepted" />
                    </Note>
                  )}
                </td>
              )}
              <td className="whitespace-nowrap py-2 px-3 text-center">
                {membership.kind !== "HOST" && (
                  <Dropdown
                    trigger={
                      <ToolbarButton size="sm">
                        <MdMoreHoriz />
                      </ToolbarButton>
                    }
                  >
                    {membership.viewerCanDelete && (
                      <Dropdown.Item
                        disabled={isDeleteMutationInFlight}
                        onClick={() => {
                          onDelete(membership.id, membership.entity.id);
                        }}
                      >
                        <FormattedMessage defaultMessage="Remove user" />
                      </Dropdown.Item>
                    )}
                    {competition.viewerCanTransferOwnership && (
                      <Dropdown.Item
                        disabled={isTransferOwnershipMutationInFlight}
                        onClick={() => {
                          onTransferOwnershipSubmit(membership.entity.id);
                        }}
                      >
                        <FormattedMessage defaultMessage="Transfer Ownership" />
                      </Dropdown.Item>
                    )}
                  </Dropdown>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <LoadMore
        loadMore={loadNext}
        hasMore={hasNext}
        isLoading={isLoadingNext}
      />
    </div>
  );
}
