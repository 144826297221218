import ErrorPage from "./ErrorPage";
import { FormattedMessage, useIntl } from "react-intl";
import { useEntityOverviewPageQuery } from "./loaders/EntityOverviewPage";
import UserMarkdown from "../components/UserMarkdown";
import { EntityBadgeList } from "../components/EntityBadgeList";
import { useSearchParams } from "react-router-dom";
import { Badge } from "../assets/badges/__generated__/badges.graphql";

export default function EntityPage() {
  const [searchParams, _] = useSearchParams();
  const intl = useIntl();
  const { variables, query } = useEntityOverviewPageQuery();
  if (!query.entityByUsername) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage(
          {
            defaultMessage: "@{username} not found",
          },
          {
            username: variables.username,
          },
        )}
      />
    );
  }
  const entity = query.entityByUsername;
  const sharedBadge = searchParams.get("badge") as Badge;
  return (
    <div className="flex flex-col gap-4">
      <div className="text-gray-500">
        {entity.bio ? (
          <UserMarkdown>{entity.bio}</UserMarkdown>
        ) : (
          <FormattedMessage defaultMessage="No description" />
        )}
      </div>
      <EntityBadgeList entity={entity} sharedBadge={sharedBadge} />
    </div>
  );
}
