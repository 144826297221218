import { ReactNode, useId } from "react";
import { cn } from "../utils/tailwind";
import { ActivityIndicator } from "./ActivityIndicator";
import clsx from "clsx";

interface Props {
  label: React.ReactNode;
  error?: React.ReactNode;
  inline?: boolean;
  children: React.ReactNode;
  isLoading?: boolean;
  hidden?: boolean;
  leading?: ReactNode;
  trailing?: ReactNode;
}

export default function FormGroup({
  label,
  inline,
  error,
  children,
  isLoading,
  hidden,
  leading,
  trailing,
}: Props) {
  const id = useId();
  return (
    <div className={cn("flex flex-col", hidden && "hidden")}>
      {leading}
      <label
        id={id}
        className={clsx(
          "gap-2",
          inline ? "inline-flex flex-row items-center" : "flex flex-col",
        )}
      >
        <span
          className={cn(
            "flex flex-row gap-1 items-center",
            isLoading && "text-gray-500",
          )}
        >
          {label}
          {isLoading && <ActivityIndicator />}
        </span>
        <div className="flex flex-col">{children}</div>
      </label>
      {error && <ErrorMessage error={error} />}
      {trailing}
    </div>
  );
}

export const ErrorMessage = ({
  error,
}: Readonly<{ error: React.ReactNode }>) => (
  <p className="pt-1 text-sm font-medium text-red-500">{error}</p>
);
